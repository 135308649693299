/*jslint node: true */
"use strict";
/*globals mod, masterUtils, _, angular, window, navigator, $ */

angular
    .module('offline', [])
    .config(OfflineConfig)
    .controller(OfflineCtrl);

OfflineConfig.$inject = ['$stateProvider'];

function OfflineConfig($stateProvider){
    $stateProvider.state('offline', {
        url: '/offline',
        views: {
            'content': {
                templateUrl: 'templates/offline.html',
                controller: 'OfflineCtrl'
            }
        }
    });
}

OfflineCtrl.$inject = ['$rootScope', '$scope', 'loadingService', '$interval', 'Bookings', '$state', 'gettextCatalog'];
function OfflineCtrl($rootScope, $scope, loadingService, $interval, Bookings, $state, gettextCatalog) {

    Bookings.clearInfoCache();

    var stop;
    var time;

    var reconnectTime = 30;

    $scope.seconds = reconnectTime;
    loadingService.disable('principal');
    loadingService.enable('middlewareError', gettextCatalog.getString("Trying to reconnect to the service automatically."));
    $scope.flag = false;

    function initReconect() {
        stop = $interval(function () {
            if ($scope.seconds === 0) {
                $scope.seconds = reconnectTime;
                $scope.reconnect();
            } else {
                $scope.seconds = $scope.seconds - 1;
            }
        }, 1000);
    }

    function stopReconnect() {
        if (angular.isDefined(stop)) {
            $interval.cancel(stop);
            stop = undefined;
        }
    }

    $scope.reconnect = function () {
        Bookings.getInfo().then(function (infoData) {
            $scope.flag = infoData;
            if($rootScope.lastState === "") {
                return $state.go("search", $rootScope.lastStateParams);
            }
            $state.go($rootScope.lastState, $rootScope.lastStateParams);
        });
    };

    function stopTime() {
        if (angular.isDefined(time)) {
            $interval.cancel(time);
            time = undefined;
        }
    }

    $scope.$on('$destroy', function () {
        stopReconnect();
        stopTime();
    });

    initReconect();
}
