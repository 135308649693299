angular.module('api', [])
    .factory('Api', ['$http', '$q', 'myConfig', '$timeout', function ($http, $q, myConfig, $timeout) {
        let cachedBooking = null;
        let cachedSections = null;
        let cachedMenu = null;
        let cachedDoc = null;
        return {
            postBooking: function (booking) {

                const defered = $q.defer();
                const tempBooking = _.cloneDeep(booking);

                $http.post(myConfig.host + "/api/forms/public/" + tempBooking.form.idForm + "?idProperty=" + myConfig.idProperty, tempBooking)
                    .success(function (data) {
                        cachedBooking = $q.defer();
                        cachedBooking.resolve(data);
                        defered.resolve(data);
                    })
                    .error(function (data, status) {
                        let errorMsg = '';
                        if (status !== 0 && data) {
                            errorMsg = data.errorMsg;
                        }
                        defered.reject(data);
                    });
                return defered.promise;
            },
            payBooking: function (booking) {
                const defered = $q.defer();
                $http
                    .post(myConfig.host + "/api/exp_checking/payBooking?idProperty=" + myConfig.idProperty, booking)
                    .success(function (data) {
                        cachedBooking = $q.defer();
                        cachedBooking.resolve(data);
                        defered.resolve(data);
                    })
                    .error(function (data, status) {
                        var errorMsg = '';
                        if ((status !== 0) && data) {
                            errorMsg = data.errorMsg;
                        }
                        defered.reject(data);
                    });
                return defered.promise;
            },
            getMenu: function (idMenu) {
                if (cachedMenu) return cachedMenu.promise;
                cachedMenu = $q.defer();

                $http.get(myConfig.host + "/api/forms/", {
                    params: {
                        idMenu: idMenu
                    }
                })
                    .success(function (menu) {
                        cachedMenu.resolve(menu);
                    })
                    .error(function (data, status) {
                        cachedMenu.reject(data);
                    });
                return cachedMenu.promise;
            },
            getSections: function (cryptedIdBooking, idBooking, email, lang) {
                // if (cachedSections) return cachedSections.promise;
                cachedSections = $q.defer();
                $http.get(myConfig.host + "/api/exp_checking/bookings", {
                    params: {
                        cryptedIdBooking: cryptedIdBooking,
                        idBooking: idBooking,
                        email: email,
                        lang: lang
                    }
                })
                    .success(function (sections) {
                        cachedSections.resolve(sections);
                    })
                    .error(function (data, status) {
                        let errorMsg = '';
                        if ((status !== 0) && data) {
                            errorMsg = data.errorMsg;
                        }
                        cachedSections.reject(errorMsg);
                    });
                return cachedSections.promise;
            },
            getDoc: function (cryptedIdBooking) {
                cachedDoc = $q.defer();
                $http.get(myConfig.host + "/api/exp_checking/generateDoc", {
                    params: {
                        cryptedIdBooking: cryptedIdBooking
                    }
                })
                    .success(function (sections) {
                        cachedDoc.resolve(sections);
                    })
                    .error(function (data, status) {
                        var errorMsg = '';
                        if ((status !== 0) && data) {
                            errorMsg = data.errorMsg;
                        }
                        cachedDoc.reject(errorMsg);
                    });
                return cachedDoc.promise;
            },
            getBookingForm: function (bookingFormParams) {
                const defered = $q.defer();

                const normalizedBookingFormParams = {
                    idProperty: myConfig.idProperty,
                    checkin: bookingFormParams.checkin.toISOString().substr(0, 10),
                    checkout: bookingFormParams.checkout.toISOString().substr(0, 10),
                    guestAges: bookingFormParams.guestAges.join(','),
                    idProduct: parseInt(bookingFormParams.idProduct, 10),
                    facilityIds: bookingFormParams.facilityIds,
                    lang: myConfig.lang,
                    promotionCode: bookingFormParams.promotionCode != null ? bookingFormParams.promotionCode.replace(/\s/g, '_') : ""
                };

                $http.get(myConfig.host + "/api/bookingForm", {params: normalizedBookingFormParams})
                    .success(function (bookingForm) {
                        defered.resolve(bookingForm);
                    })
                    .error(function (data, status) {
                        let errorMsg = '';
                        if ((status !== 0) && data) {
                            errorMsg = data.errorMsg;
                        }
                        if (data && data.errorCode === "input.invalidPromotionalCode") {
                            myConfig.promotionCode = "";
                            // TODO ¿ Poner error traducido para el usuario
                            // errorMsg =
                        }
                        defered.reject(errorMsg);
                    });
                return defered.promise;
            },
            clearCache: function () {
                cachedSections = null;
                cachedBooking = null;
            }
        };
    }]);
