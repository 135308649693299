/*jslint node: true */
"use strict";
/*globals mod, masterUtils, _, angular, window, navigator, $ */

angular
    .module('info_request', [])
    .config(InfoRequestConfig)
    .controller('InfoRequestController', InfoRequestController);

InfoRequestConfig.$inject = ['$stateProvider'];

function InfoRequestConfig($stateProvider) {
    $stateProvider.state('inforeq', {
        url: '/inforeq',
        views: {
            'content': {
                templateUrl: 'templates/info_request.html',
                controller: 'InfoRequestController'
            }
        }
    });
}

InfoRequestController.$inject = ['$scope', '$state', 'alertsService', 'loadingService', '$http', 'myConfig', 'gettextCatalog'];

function InfoRequestController($scope, $state, alertsService, loadingService, $http, myConfig, gettextCatalog) {

    loadingService.disable('principal');

    $scope.user = {};
    $scope.submitted = false;
    $scope.success = false;
    $scope.mailSent = false;

    $scope.submitForm = function (form) {
        $scope.submitted = true;
        if (form.$valid) {
            loadingService.enable('rooms', gettextCatalog.getString("Sending form..."));
            $http.post(myConfig.host + "/api/inforequest?idProperty=" + myConfig.idProperty, $scope.user)
                .success(function (data) {
                    alertsService.new("infoReqSuccess", "success", "Info request has been sent successfully.");
                    $scope.success = true;
                    $scope.mailSent = true;
                })
                .error(function (data, status) {
                    let errorMsg = '';
                    if ((status !== 0) && data) {
                        errorMsg = data.errorMsg;
                        alertsService.newError("Unexpected error in service", errorMsg);
                    }
                    $scope.success = false;
                    $scope.mailSent = true;
                });
        }
    };

}