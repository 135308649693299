/*jslint node: true */
"use strict";
/*globals mod,masterUtils,_, angular, window, globalConfig */

angular.module('additional_concepts', [])

    .directive('additionalConceptBoolean', function () {
        return {
            // templateUrl: 'templates/additional_concept_boolean.html',
            templateUrl: function () {
                const template = 'templates/additional_concept_boolean.html';
                return globalConfig.production ?
                    `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                    template;
            },
            scope: {
                ngAdditionalConcept: '=',
                ngModel: '=',
                base: '='
            },
            restrict: 'A',
            controller: ["$scope", function ($scope) {
                $scope.getUnitPrice = function () {
                    return $scope.unitPrice;
                };

                $scope.calculate = function () {
                    const additionalConcepts = _.clone($scope.$parent.booking.additionalConcepts);
                    additionalConcepts[$scope.ngAdditionalConcept.idAdditionalConcept] = true;
                    const getPrice = $scope.getPrice || $scope.$parent.getPrice;
                    const budget = getPrice(additionalConcepts);
                    if (budget) {
                        const importChecked = budget.getImport("total", masterUtils.Price2.attrFilter("_RSV4_ADDITIONAL_CONCEPT_" + $scope.ngAdditionalConcept.idAdditionalConcept));
                        $scope.unitPrice = importChecked;
                    }
                };

                $scope.$on("calculate", function () {
                    $scope.calculate();
                });

                $scope.calculate();

                $scope.delete = function () {
                    $scope.ngModel = false;
                };
            }]
        };
    })

    .directive('additionalConceptQuantity', function () {
        return {
            // templateUrl: 'templates/additional_concept_quantity.html',
            templateUrl: function () {
                const template = 'templates/additional_concept_quantity.html';
                return globalConfig.production ?
                    `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                    template;
            },
            scope: {
                ngAdditionalConcept: '=',
                ngModel: '=',
                base: '='
            },
            restrict: 'A',
            controller: ["$scope", function ($scope) {
                $scope.getUnitPrice = function () {
                    return $scope.unitPrice;
                };

                $scope.getImport = function () {
                    return $scope.getUnitPrice() * $scope.ngModel;
                };

                $scope.range = function (min, max, step) {
                    step = step || 1;
                    min = Number.isInteger(min) ? min : 0;
                    const input = [];
                    for (let i = min; i <= max; i += step) {
                        input.push(i);
                    }
                    return input;
                };

                $scope.calculate = function () {
                    const additionalConcepts = _.clone($scope.$parent.booking.additionalConcepts);
                    additionalConcepts[$scope.ngAdditionalConcept.idAdditionalConcept] = 1;
                    const getPrice = $scope.getPrice || $scope.$parent.getPrice;
                    const budget = getPrice(additionalConcepts);
                    if (budget) {
                        const importChecked = budget.getImport("total", masterUtils.Price2.attrFilter("_RSV4_ADDITIONAL_CONCEPT_" + $scope.ngAdditionalConcept.idAdditionalConcept));
                        $scope.unitPrice = importChecked;
                    }
                };

                $scope.$on("calculate", function () {
                    $scope.calculate();
                });

                $scope.calculate();

                $scope.delete = function () {
                    $scope.ngModel = 0;
                };
            }]
        };
    })

    .directive('additionalConceptSingleChoice', function () {
        return {
            // templateUrl: 'templates/additional_concept_single_choice.html',
            templateUrl: function () {
                const template = 'templates/additional_concept_single_choice.html';
                return globalConfig.production ?
                    `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                    template;
            },
            scope: {
                ngAdditionalConcept: '=',
                ngModel: '=',
                base: '='
            },
            restrict: 'A',
            controller: ["$scope", function ($scope) {
                $scope.unitPrice = {};

                $scope.getOptionPrice = function (option) {
                    return $scope.unitPrice[option.idOption];
                };

                $scope.calculate = function () {
                    const additionalConcepts = _.clone($scope.$parent.booking.additionalConcepts);
                    _.each($scope.ngAdditionalConcept.options, function (option) {
                        additionalConcepts[$scope.ngAdditionalConcept.idAdditionalConcept] = option.idOption;
                        const getPrice = $scope.getPrice || $scope.$parent.getPrice;
                        const budget = getPrice(additionalConcepts);
                        if (budget) {
                            const importChecked = budget.getImport("total", masterUtils.Price2.attrFilter("_RSV4_ADDITIONAL_CONCEPT_" + $scope.ngAdditionalConcept.idAdditionalConcept));
                            $scope.unitPrice[option.idOption] = importChecked;
                        }
                    });
                };

                $scope.$on("calculate", function () {
                    $scope.calculate();
                });

                $scope.calculate();

                $scope.delete = function () {
                    $scope.ngModel = $scope.ngAdditionalConcept.def || null;
                };
            }]
        };
    });
