/*jslint node: true */
"use strict";
/*globals mod, masterUtils, _, angular, window, navigator, $ */

angular
    .module('booking_payment', [])
    .controller('BookingPaymentCtrl', ['$scope',
        function ($scope) {

            const now = new Date();
            $scope.expirationDates = [];

            $scope.months = [];
            $scope.years = [];

            $scope.ccExpirationDate = {};

            for (let i = 1; i <= 12; i++) {
                let currentMonth = i;
                if (currentMonth < 10) {
                    currentMonth = '0' + currentMonth;
                }
                $scope.months.push({
                    name: currentMonth,
                    value: currentMonth
                });
            }

            for (let j = 1; j <= 10; j++) {
                $scope.years.push({
                    name: now.getFullYear(),
                    value: now.getFullYear().toString().slice(2)
                });
                now.setYear(now.getFullYear() + 1);
            }

            $scope.ccedChangued = function () {
                if ($scope.ccExpirationDate.year && $scope.ccExpirationDate.month) {
                    $scope.booking.payment.ccExpirationDate = $scope.ccExpirationDate.year + $scope.ccExpirationDate.month;
                }
            };

            $scope.getDownpaymentOptionsLength = function () {
                if (!$scope.bookingForm.downpaymentOptions) return 0;
                return Object.keys($scope.bookingForm.downpaymentOptions).length;
            };

            $scope.filterRgpd = function (consent) {
                const product = $scope.$parent.product;
                return product.isExperience ? consent.apply_experiences : consent.apply_master_booking;
            };

        }
    ]);
