angular
    .module('utils', [])
    .filter('trust', ['$sce', function ($sce) {
        return function (text) {
            if (typeof text != 'string') return '';
            return $sce.trustAsHtml(text);
        };
    }])
    .directive('onErrorSrc', function () {
        return {
            link: function (scope, element, attrs) {
                element.bind('error', function () {
                    if (attrs.src != attrs.onErrorSrc) {
                        attrs.$set('src', attrs.onErrorSrc);
                    }
                });
            }
        };
    });