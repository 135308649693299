/*jslint node: true */
"use strict";
/*globals angular, globalConfig */

angular.module('dynamic_content', [])

    .directive('dynamicFooterBooking', ['$rootScope', '$window', '$compile', '$http', '$templateCache', 'myConfig',
        function ($rootScope, $window, $compile, $http, $templateCache, myConfig) {

            if (!myConfig.footerBookingEnabled) return {};
            return {
                restrict: 'A',
                link: function (scope, element) {
                    var footerBooking;

                    function insertComponents() {
                        $compile(footerBooking)(scope);
                        if (!scope.$$phase) {
                            scope.$apply(function () {
                                element.append(footerBooking);
                            });
                        } else {
                            element.append(footerBooking);
                        }
                    }

                    const dynamicFooterBookingPath = myConfig.production ?
                        `${myConfig.middlewareHost}/${myConfig.rootName}/templates/footer_booking.html?version=${myConfig.version}` :
                        'templates/footer_booking.html';

                    $http.get(dynamicFooterBookingPath, {cache: $templateCache})
                        .success(function (tplContent) {
                            footerBooking = angular.element(tplContent);
                            insertComponents();
                        });
                }
            };
        }])

    .directive('dynamicHeader', ['$rootScope', '$window', '$compile', '$http', '$templateCache', 'Bookings',
        function ($rootScope, $window, $compile, $http, $templateCache, Bookings) {

            const dynamicHeaderPath = globalConfig.production ?
                `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/header.html?version=${globalConfig.version}` :
                'templates/header.html';

            let header;
            $http.get(dynamicHeaderPath, {cache: $templateCache})
                .success(function (tplContent) {
                    header = angular.element(tplContent);
                });

            return {
                restrict: 'A',
                link: function (scope, element, attrs) {

                    Bookings.getInfo()
                        .then(function (info) {
                            if (info.contents.Header && info.contents.Header !== "") {
                                header = angular.element(info.contents.Header);
                            }
                            insertComponents();
                        });

                    element.on('load', insertComponents);

                    function insertComponents() {
                        if (!header) {
                            setTimeout(insertComponents, 500);
                        }
                        $compile(header)(scope);

                        if (!scope.$$phase) {
                            scope.$apply(function () {
                                element.append(header);
                            });
                        } else {
                            element.append(header);
                        }

                    }

                }
            };

        }])

    .directive('dynamicFooter', ['$rootScope', '$window', '$compile', '$http', '$templateCache', 'Bookings', 'myConfig', '$sce',
        function ($rootScope, $window, $compile, $http, $templateCache, Bookings, myConfig, $sce) {

            const footerPath = myConfig.production ?
                `${myConfig.middlewareHost}/${myConfig.rootName}/templates/footer.html?version=${myConfig.version}` :
                'templates/footer.html';

            let footer;
            $http.get(footerPath, {cache: $templateCache})
                .success(function (tplContent) {
                    footer = angular.element(tplContent);
                });

            return {
                restrict: 'A',
                link: function (scope, element, attrs) {

                    Bookings.getInfo()
                        .then(function (info) {
                            if (info.contents.Footer && info.contents.Footer !== "") {
                                footer = angular.element(info.contents.Footer);
                            }
                            insertComponents();
                        });

                    function insertComponents() {
                        if (!footer) {
                            setTimeout(insertComponents, 500);
                        }

                        $compile(footer)(scope);
                        if (!scope.$$phase) {
                            scope.$apply(function () {
                                element.append(footer);
                            });
                        } else {
                            element.append(footer);
                        }

                    }

                }
            };

        }]);
