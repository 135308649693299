angular
    .module('shared_module', [
        'utils',
        'popups',
        'Bookings',
        'alerts',
        'bg_img',
        'auto_height',
        'google_places',
        'loading_service',
        'magnific_popup',
        'dynamic_content',
        'birth_day_picker',
        'payment_methods',
        'signature',
        'aliceBiometrics',
        'moncompte',
        'myaccount',
        'promotion_service',
    ]);
