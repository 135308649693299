angular.module('moncompte', [])
    .factory('MonCompte', ['$location', function ($location) {

        let tenantId = null;
        let monCompteTokenString = localStorage.getItem("moncompte:session");
        let monCompteToken = monCompteTokenString != null ? JSON.parse(monCompteTokenString) : null;
        let monComptePersonId = null;
        let monComptePerson = null;
        let monCompteUrl = 'https://mw.edm.eseason.com';
        let monCompteTokenFromUrlQuery = false;
        if (monCompteTokenString != null) {
            monCompteTokenFromUrlQuery = true;
        }

        return {
            getMonComptePersonId: () => monComptePersonId,
            setMonCompteBase64Token: function (session) {
                try {
                    const monCompteTokenFromBase64 = atob(session);
                    localStorage.setItem("moncompte:session", monCompteTokenFromBase64);
                    monCompteToken = JSON.parse(monCompteTokenFromBase64);
                } catch (error) {
                    console.log("error", error);
                }
            },
            loadScript: function (tenantIdentifier) {
                if (tenantIdentifier == null) {
                    return;
                }
                const script = `
                    !function (_m, o, n, c, O, m, p, t, e) {
                    o[_m] = o[_m] || function () {
                        if (1 === arguments.length) return o[_m].a[arguments[0]] || null;
                        o[_m].a = o[_m].a || [], o[_m].a[arguments[0]] = arguments[1]
                    }, (O = n.createElement(c)).async = 1,
                        O.src = 'https://widget.edm.eseason.com/app.js',
                        (m = n.getElementsByTagName(c)[0]).parentNode.insertBefore(O, m)
                    }('SQS_MonCompte', window, document, 'script');
                    window.SQS_MonCompte('tenant', ${tenantId})
                    window.SQS_MonCompte('api_uri', 'https://mw.edm.eseason.com');
                    window.SQS_MonCompte('dist_uri', 'https://widget.edm.eseason.com');
                `;
                const scriptElement = document.createElement('script');
                scriptElement.innerHTML = script;
                document.body.appendChild(scriptElement);
                this.loadMcsModScript();
            },
            loadMonCompte: function (tenantIdentifier) {
                return new Promise((resolve) => {
                    if (tenantIdentifier == null) {
                        return;
                    }
                    tenantId = tenantIdentifier;
                    const monCompteToken = $location.search().monCompteToken;
                    if (monCompteToken != null) {
                        this.setMonCompteBase64Token(monCompteToken);
                        $location.search('monCompteToken', null);
                        monCompteTokenFromUrlQuery = true;
                    }
                    this.loadAccount()
                        .finally(() => {
                            resolve();
                        });

                });
            },
            loadAccount: function () {
                return new Promise((resolve) => {
                    if (monCompteToken != null) {
                        const accountUrl = `${monCompteUrl}/${tenantId}/moncompte/account`;
                        fetch(accountUrl, {
                            method: 'GET',
                            headers: {
                                'X-Session-Id': monCompteToken.session,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                monComptePerson = data.person;
                                monComptePersonId = data.customerId;
                                localStorage.setItem("moncompte:person", JSON.stringify(data.person));
                                this.loadScript(tenantId);
                                resolve(data);
                            })
                            .catch(error => {
                                console.log('Error:', error.toString());
                                resolve();
                            });
                    } else {
                        this.loadScript(tenantId);
                        resolve();
                    }
                });
            },
            getFormattedData: function () {
                if (monComptePerson == null) {
                    return null;
                }
                const splittedLastname = monComptePerson.lastName.split(' ');
                const lastName = splittedLastname.shift();
                const secondLastName = splittedLastname.join(' ');
                return {
                    idClientGroup: monComptePersonId,
                    firstName: monComptePerson.firstName,
                    lastName: lastName,
                    secondLastName: secondLastName,
                    email: monComptePerson.email,
                    phone: monComptePerson.phone,
                    cellPhone: monComptePerson.mobilePhone,
                    address: monComptePerson.address1 + (monComptePerson.address2 != null ? ' ' + monComptePerson.address2 : ''),
                    zip: monComptePerson.postCode,
                    city: monComptePerson.city,
                    country: monComptePerson.country,
                    birthdate: monComptePerson.birthDate != null ? new Date(monComptePerson.birthDate) : null,
                    language: monComptePerson.language,
                    treatment: monComptePerson.civility != null ? monComptePerson.civility.value : null
                };
            },
            loadMcsModScript: function () {
                const script = document.createElement('script');
                script.src = `/libs/mcs-mon-compte-mod.min.js`;
                document.body.appendChild(script);
                script.onload = () => {
                    const host = window.location.origin;
                    const mcsMomCompte = new window.MCS_MonCompte({
                        urlBooking: host,
                        removeButtonStayDetails: true,
                        removeButtonStayImage: true,
                        blockAllExternalAnchors: true
                    });
                    mcsMomCompte.start();
                    if (monCompteTokenFromUrlQuery) {
                        this.showButton();
                    }
                };
            },
            showButton: function () {
                const monCompteBox = document.getElementById('my-account-box');
                if (monCompteBox != null) {
                    monCompteBox.style.display = 'block';
                }
            }
        };
    }]);
