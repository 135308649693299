/*jslint node: true */
"use strict";
/*globals angular, alert */

angular.module('price_viewer', [])

    .directive('priceViewer', ['gettextCatalog', function (gettextCatalog) {
        return {
            templateUrl: 'templates/price_viewer.html',
            scope: {
                price: '='
            },
            restrict: 'A',
            replace: true,
            controller: ["$scope", function ($scope) {
                $scope.getTree = function () {
                    if (!$scope.price) {
                        return null;
                    }
                    var tree = $scope.price.renderTree();
                    tree.label = gettextCatalog.getString('Total');
                    tree.expanded = true;
                    return tree;
                };
            }]
        };
    }]);
