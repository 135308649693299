/*jslint node: true */
/*global angular, document, masterUtils ,_, globalConfig */

"use strict";

angular
    .module('stay_info', [])
    .component('stayInfo', {
        // templateUrl: 'templates/stay_info.html',
        templateUrl: function () {
            return globalConfig.production ?
                `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/stay_info.html` :
                'templates/stay_info.html';
        },
        bindings: {
            booking: '=',
        }
    });
