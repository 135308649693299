/*jslint node: true */
"use strict";
/*globals angular, globalConfig */

angular
    .module('search', ['search_filter', 'search_filter_result', 'search_filter_param', 'calendar_widget'])
    .config(SearchConfig);

SearchConfig.$inject = ['$stateProvider'];

function SearchConfig($stateProvider) {

    const templateUrl = globalConfig.production ?
        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/filter.html?version=${globalConfig.version}` :
        'templates/filter.html';

    $stateProvider
        .state('search', {
            reloadOnSearch: false,
            url: '/search?checkin&checkout&guestAges&categoryIds&facilityIds&lang&promotionCode&ra&trackref&guid&experiences&origin&monCompteToken&myAccountToken',
            views: {
                content: {
                    templateUrl: templateUrl,
                    controller: 'FilterCtrl'
                }
            }
        });
}

