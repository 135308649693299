/*jslint node: true */
"use strict";
/*globals angular, MasterWidget, _ */

angular.module('categories_selector', [])
    .directive('categoriesSelector', ['myConfig', 'gettextCatalog', 'Bookings', '$location',
        function (myConfig, gettextCatalog, Bookings, $location) {
            return {
                templateUrl: 'templates/categories_selector.html',
                scope: {
                    selection: '=',
                    onChange: '='
                },
                restrict: 'E',
                link: function ($scope, element, attrs) {

                    const eCategories = element.children().children()[0];
                    const wCategories = new MasterWidget.CategoriesSelector(eCategories, {
                        title: gettextCatalog.getString("Select categories"),
                        dropdown: false,
                        lang: myConfig.lang
                    });

                    let experienceCategories = [];
                    let bookingsCategories = [];

                    function protect(f) {
                        const phase = $scope.$root.$$phase;
                        if (phase == '$apply' || phase == '$digest') {
                            f();
                        } else {
                            $scope.$apply(f);
                        }
                    }

                    wCategories.onChange = function (cats) {
                        protect(function () {
                            // const otherCategories = $scope.selection.categoryIds
                            //     .filter(idCategory => !experienceCategories.includes(idCategory));
                            // $scope.selection.categoryIds = [...otherCategories, ...cats];
                            $scope.selection.categoryIds = cats;
                        });
                    };

                    Bookings.getInfo().then(function (newInfo) {

                        const info = _.cloneDeep(newInfo);

                        // Normalize categories to string.
                        info.products = info.products.map(p => {
                            p.categories = p.categories.map((idCategory) => idCategory.toString());
                            return p;
                        });

                        info.categoryGroups = info.categoryGroups.map((cg) => {
                            cg.idCategoryGroup = cg.idCategoryGroup.toString();
                            return cg;
                        });

                        info.categories = info.categories.map(c => {
                            c.idCategory = c.idCategory.toString();
                            if (c.idCategoryGroup != null) {
                                c.idCategoryGroup = c.idCategoryGroup.toString();
                            }
                            return c;
                        });

                        info.products = info.products.filter(function (product) {
                            if (product.isExperience) {
                                product.categories.forEach(idCategory => {
                                    if (!experienceCategories.includes(idCategory)) {
                                        experienceCategories.push(idCategory);
                                    }
                                });
                            } else {
                                product.categories.forEach(idCategory => {
                                    if (!bookingsCategories.includes(idCategory)) {
                                        bookingsCategories.push(idCategory);
                                    }
                                });
                                return false;
                            }
                            return product.isExperience;
                        });

                        info.categories = info.categories.filter(function (category) {
                            return experienceCategories.includes(category.idCategory);
                        });

                        wCategories.setInfo(info);

                        if ($scope.selection) {
                            const actualSelection = $scope.selection.categoryIds.map(idCategory => idCategory.toString());
                            let experienceCategoriesSelected = actualSelection.filter(idCategory =>
                                experienceCategories.includes(idCategory) && !bookingsCategories.includes(idCategory));
                            if (experienceCategoriesSelected.length === 0) {
                                actualSelection.push(...experienceCategories);
                            }
                            $scope.selection.categoryIds = actualSelection.reduce((acc, idCategory) => {
                                if (!acc.includes(idCategory)) {
                                    acc.push(idCategory);
                                }
                                return acc;
                            }, []);
                        }

                        if (Array.isArray(myConfig.expandedCategoryGroups)) {
                            wCategories.setCategoryGroups(myConfig.expandedCategoryGroups);
                        } else {
                            // Expand all
                            info.categories.forEach(function (category) {
                                if (category.idCategoryGroup) {
                                    wCategories.expandCategoryGroup(category.idCategoryGroup);
                                }
                            });
                        }


                    });

                    $scope.$watch('selection', function () {
                        if ($scope.selection) {
                            wCategories.setSelectedCategories($scope.selection.categoryIds);
                            if ($scope.onChange != null) {
                                setTimeout(() => {
                                    $scope.onChange();
                                }, 1000);
                            }
                        }
                    }, true);

                }
            };
        }]);
