/*jslint node: true */
"use strict";
/*globals masterUtils, angular, _ */

angular
    .module('booking_budget', ['budget_item'])
    .controller('BookingBudgetCtrl', ['$scope', '$interpolate', 'gettextCatalog', 'myConfig', 'ngDialog',
    function ($scope, $interpolate, gettextCatalog, myConfig, ngDialog) {

        $scope.lang = myConfig.lang;

        const DU = masterUtils.dateUtils;

        $scope.openInfoPopup = function (itemData) {
            var tmpData = _.clone(itemData);
            if(!tmpData.longDescription) {
                tmpData = $scope.wvg;
            }
            ngDialog.open({
                template: 'templates/infoPopup.html',
                showClose: true,
                className: 'wvgPopup',
                controller: ["$scope" , function ($scope) {
                    $scope.lang = myConfig.lang;
                    $scope.infoData = tmpData;
                    if ($scope.infoData.gallery.length > 0) {
                        $scope.tabSelected = 0;
                        return;
                    }
                    if ($scope.infoData.longDescription) {
                        $scope.tabSelected = 1;
                        return;
                    }
                    if ($scope.infoData.maps.length > 0) {
                        $scope.tabSelected = 2;
                        return;
                    }
                    if ($scope.infoData.plants.length > 0) {
                        $scope.tabSelected = 3;
                        return;
                    }
                    if ($scope.infoData.promotionDescription && $scope.infoData.promotionDescription[$scope.lang]) {
                        $scope.tabSelected = 4;
                        return;
                    }
                }]
            });
        };

        $scope.getPeriods = function () {
            if ((!$scope.booking.checkout) || (!$scope.booking.checkin)) {
                return "";
            }
            const p = DU.date2int($scope.booking.checkout) - DU.date2int($scope.booking.checkin);
            $scope.nights = p;
            if (p === 1) {
                return gettextCatalog.getString("night");
            } else {
                return gettextCatalog.getString("nights");
            }
        };

        $scope.getAdults = function () {
            let a = 0;
            _.each($scope.booking.guestAges, function (age) {
                if (age >= 18) {
                    a += 1;
                }
            });
            $scope.adults = a;
            if (a === 1) {
                return gettextCatalog.getString("adult");
            } else {
                return gettextCatalog.getString("adults");
            }
        };

        $scope.getChilds = function () {
            const childAges = [];
            _.each($scope.booking.guestAges, function (age) {
                if (age < 18) {
                    childAges.push(age);
                }
            });
            if (childAges.length === 0) {
                return null;
            }
            $scope.minors = childAges.length;
            $scope.ages = childAges.join(', ');
            if (childAges.length === 1) {
                return gettextCatalog.getString("child of age");
            } else {
                return gettextCatalog.getString("childrens of ages");
            }
        };

        $scope.getProductShortDescription = function () {
            if (!$scope.initialized) {
                return "";
            }
            const product = _.findWhere($scope.info.products, {idProduct: $scope.bookingFormParams.idProduct});
            return product.shortDescription;
        };
    }
]);
