/*jslint node: true */
"use strict";
/*globals  angular, globalConfig */

angular
    .module('budget_item_extended', ['recursion_helper'])
    .directive('budgetItemExtended', function () {
        return {
            // templateUrl: 'templates/budget_item_extended.html',
            templateUrl: function () {
                const template = 'templates/budget_item_extended.html';
                return globalConfig.production ?
                    `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                    template;
            },
            scope: {
                title: '=',
                bgItem: '=',
                level: '='
            },
            restrict: 'E',
            link: function (scope) {
                scope.toggle = function (line, index) {
                    line.expanded = !line.expanded;
                    const groupBy = Array.isArray(line.groupBy) ? line.groupBy : [line.groupBy];
                    for (let i = index + 1; i < scope.bgItem.length; i++) {
                        const childLine = scope.bgItem[i];
                        if (childLine.attributes && childLine.attributes.some(attribute => groupBy.includes(attribute))) {
                            childLine.show = line.expanded;
                        } else {
                            break;
                        }
                    }
                };
            }
        };
    });
