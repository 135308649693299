/*jslint node: true */
"use strict";
/*globals mod, masterUtils, _, angular, window, navigator, $, dataLayer, globalConfig, CustomEvent */

angular
    .module('search_filter_result', ['dynamic_child'])
    .controller('FilterResultCtrl', ['$rootScope', '$scope', 'gettextCatalog', 'Bookings', 'ngDialog', 'myConfig',
        function ($rootScope, $scope, gettextCatalog, Bookings, ngDialog, myConfig) {

            $scope.validateMinInfo = function (p) {
                return (p.gallery.length > 0 || p.plants.length > 0 || p.maps.length > 0 || p.longDescription ||
                    (p.promotionDescription && p.promotionDescription[myConfig.lang]));
            };

            $scope.getCSSClass = function (p, discount) {
                const cssClass = {
                    'hasLongDescription': $scope.validateMinInfo(p),
                    'hasShortDescription': p.shortDescription
                };
                if (discount !== null && discount !== undefined && discount !== 0) {
                    cssClass.hasDiscount = true;
                }
                if (p.extraOptions !== undefined && p.extraOptions !== null && p.extraOptions.template) {
                    cssClass[p.extraOptions.template] = true;
                }
                if (p.mode != null) {
                    cssClass[p.mode] = true;
                }
                return cssClass;
            };

            $scope.openCalendar = function ($event, product) {
                $event.stopPropagation();
                const event = new CustomEvent('openCalendarPopup', {
                    detail: {
                        product
                    }
                });
                window.dispatchEvent(event);
            };

            $scope.openInfoPopup = function (productGroupData, productData) {
                const itemData = productData || productGroupData;
                const isProduct = productData != null && Object.keys(productData).length > 0;
                if ($scope.validateMinInfo(itemData)) {
                    if (isProduct && typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                        if (myConfig.GoogleTagManagerVersion == 2) {
                            dataLayer.push({ecommerce: null});
                            dataLayer.push({
                                event: "view_item",
                                ecommerce: {
                                    items: [Bookings.processGtmProduct(itemData)],
                                    currency: 'EUR',
                                    value: itemData.totalPrice
                                }
                            });
                        }
                    }

                    const dialogTemplate = globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/infoPopup.html` :
                        'templates/infoPopup.html';

                    ngDialog.open({
                        template: dialogTemplate,
                        showClose: true,
                        className: 'wvgPopup',
                        controller: ["$scope", function ($scope) {
                            $scope.infoData = itemData;
                            $scope.lang = myConfig.lang;
                            if ($scope.infoData.gallery.length > 0) {
                                $scope.tabSelected = 0;
                                return;
                            }
                            if ($scope.infoData.longDescription) {
                                $scope.tabSelected = 1;
                                return;
                            }
                            if ($scope.infoData.plants.length > 0) {
                                $scope.tabSelected = 2;
                                return;
                            }
                            if ($scope.infoData.maps.length > 0) {
                                $scope.tabSelected = 3;
                                return;
                            }
                            if ($scope.infoData.promotionDescription && $scope.infoData.promotionDescription[$scope.lang]) {
                                $scope.tabSelected = 4;
                                return;
                            }
                        }]
                    });

                }

            };

            $scope.showDates = function (product) {
                product.preSelected = !product.preSelected;
            };

            const availabilityCache = {};

            $scope.getProductAvailability = function (product) {
                if (availabilityCache[product.idProduct]) return availabilityCache[product.idProduct];
                Bookings.getProductAvailability(product.idProduct).then(function (availability) {
                    availabilityCache[product.idProduct] = availability;
                });
            };

            $scope.showOthersCall = function () {
                $rootScope.showOthers = !$rootScope.showOthers;
            };

            $scope.enableMapProcess = function (product) {
                $scope.mapMode = true;
            };

            $scope.disableMapProcess = function () {
                $scope.mapMode = false;
            };

        }]);
