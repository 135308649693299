/*jslint node: true */
"use strict";
/*globals angular, window, globalConfig */

angular
    .module('alerts', [])
    .factory('alertsService', ['$rootScope', '$timeout', '$compile', '$state', 'gettextCatalog',
        function ($rootScope, $timeout, $compile, $state, gettextCatalog) {
            $rootScope.alerts = [];

            return {
                new: function (id, type, message, hideTimeout) {
                    const self = this;

                    let returnFlag = false;
                    $rootScope.alerts.forEach(function (alert) {
                        if (alert.id === id && alert.message === message) {
                            returnFlag = true;
                        }
                    });
                    if (returnFlag) return;

                    $rootScope.alerts.push({id, type, message});

                    if (hideTimeout) {
                        $timeout(function () {
                            self.remove(id);
                        }, hideTimeout);
                    }

                },
                reset: function () {
                    $rootScope.alerts = [];
                },
                remove: function (id) {
                    var tempAlerts = [];
                    $rootScope.alerts.forEach(function (alert, i) {
                        if (alert.id != id) {
                            tempAlerts.push(alert);
                        }
                    });
                    $rootScope.alerts = tempAlerts;
                },
                newError: function (msg, err, showError) {

                    var scope = $rootScope.$new();
                    scope.showError = showError || false;
                    scope.msg = msg;
                    scope.err = err;

                    console.error(err);

                    if (typeof err == "object") {
                        try {
                            scope.err = JSON.parse(err);
                        } catch (tryError) {
                            console.error(tryError);
                            scope.err = err;
                        }
                    }

                    if (window._loq) {
                        window._loq.push(["tag", "Error"]);
                    }

                    var errorTemplate =
                        '<div class="alertBox alert_type_error" role="alert" style="margin-top:10px;">' +
                        '<div style="width: 90%; border-bottom: 1px solid">' +
                        '<span class="alertClose cursor-pointer" data-dismiss="alert" aria-label="Close" ng-click="close()"><span aria-hidden="true">&times;</span></span>' +
                        '<strong> {{ msg }}</strong>' +
                        '<span ng-show="err && !showError" ng-click="showError=true"> ' + gettextCatalog.getString("Show error detail") + ' </span>' +
                        '<pre ng-show="err && showError" >{{ err.toString() }}</pre>' +
                        '</div>' +
                        '<div>' + gettextCatalog.getString("Please contact the site if the error recurs. Sorry for inconvenience.") + '</div>' +
                        '<strong ng-click="showContact()">' + gettextCatalog.getString("Show contact form") + '</strong>' +
                        '</div>';

                    var errorAlert = angular.element(errorTemplate);
                    $compile(errorAlert)(scope);
                    angular.element('#alertsBox').append(errorAlert);

                    scope.close = function () {
                        errorAlert.remove();
                    };

                    scope.showContact = function () {
                        errorAlert.remove();
                        $state.go('inforeq');
                    };

                }
            };
        }])
    .directive('alertsBox', [function () {
        return {
            // templateUrl: "templates/alerts.html",
            templateUrl: function () {
                const template = 'templates/alerts.html';
                return globalConfig.production ?
                    `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                    template;
            },
            controller: ["$scope", "alertsService", function ($scope, alertsService) {
                $scope.close = function (a) {
                    alertsService.remove(a.id);
                };
            }]
        };
    }]);
