/*jslint node: true */
/*global angular */
/*global document */

"use strict";

angular
    .module('master_form', [])
    .controller('masterFormController', ['$scope', function ($scope) {
        var vm = this;
    }])
    .directive('masterForm', ['Api',
        function (Api) {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'templates/master_form.html',
                controller: 'masterFormController',
                controllerAs: 'vm',
                scope: {
                    sections: '='
                },
                link: function (scope, element, attrs) {
                    scope.$watch('sections', function(){
                        scope.vm.sections = scope.sections;
                    });
                }
            };
        }]);
