/*jslint node: true */
/*global angular, document, _ ,globalConfig */

"use strict";

angular
    .module('payment_methods', [])
    .directive('paymentMethods', ['myConfig', 'gettextCatalog',
        function (myConfig, gettextCatalog) {
            return {
                restrict: 'E',
                // templateUrl: 'templates/payment_methods.html',
                templateUrl: function () {
                    const template = 'templates/payment_methods.html';
                    return globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                        template;
                },
                scope: {
                    paymentMethods: '=methods',
                    selected: '=',
                    parentForm: '=form',
                    lang: '='
                },
                link: function (scope, element, attrs) {

                    /**
                     * Default params
                     */
                    scope.ccExpirationDate = {};
                    scope.months = [];
                    scope.years = [];

                    const now = new Date();

                    for (let i = 1; i <= 12; i++) {
                        let currentMonth = i;
                        if (currentMonth < 10) {
                            currentMonth = '0' + currentMonth;
                        }
                        scope.months.push({
                            name: currentMonth,
                            value: currentMonth
                        });
                    }

                    for (let j = 1; j <= 10; j++) {
                        scope.years.push({
                            name: now.getFullYear(),
                            value: now.getFullYear().toString().slice(2)
                        });
                        now.setYear(now.getFullYear() + 1);
                    }

                    function initComponent(){

                        /**
                         * Select default payment method
                         */
                        const pm = scope.paymentMethods[0];

                        if (scope.selected == null) {
                            scope.selected = {};
                        }

                        if (pm != null) {
                            scope.selected.idPaymentConditionRule = pm.idPaymentConditionRule;
                            scope.paymentMethod = pm;
                            if (pm.paymentClass === "CREDIT_CARD" || pm.paymentClass === "CREDITCARD_TPVPC" || pm.paymentClass === "CREDITCARD_SAVE") {
                                scope.creditCardDefault = pm.paymentClass;
                                scope.creditCardDefaultName = pm.name;
                            }
                        }

                        _.each(scope.paymentMethods, function (pmItem) {
                            if (pmItem.paymentClass === "CREDIT_CARD" || pmItem.paymentClass === "CREDITCARD_TPVPC" || pmItem.paymentClass === "CREDITCARD_SAVE") {
                                angular.element(".tarjeta-wrapper").show();
                                angular.element(".CREDIT_CARD_box").show();
                            }
                            if (pmItem.paymentClass === "BANKTRANSFER") {
                                angular.element(".transferencia-wrapper").show();
                                angular.element(".BANKTRANSFER_box").show();
                            }
                            if (pmItem.paymentClass === "ATCHECKIN") {
                                angular.element(".atcheckin-wrapper").show();
                                angular.element(".ATCHECKIN_box").show();
                            }
                        });
                    }

                    scope.$watch("paymentMethods", function(newValue){
                        if (newValue && Object.keys(newValue).length > 0){
                            initComponent();
                        }
                    });

                    scope.isCreditCard = function(paymentMethod) {
                        return paymentMethod != null && (paymentMethod.paymentClass === "CREDITCARD_SAVE" || paymentMethod.paymentClass === "CREDITCARD_TPVPC" || paymentMethod.paymentClass === "CREDITCARD");
                    };

                    scope.selectMethod = function (dpoItem) {
                        scope.paymentMethod = dpoItem;
                        scope.selected.idPaymentConditionRule = dpoItem.idPaymentConditionRule;
                        if (dpoItem.paymentClass === 'BANKTRANSFER') {
                            delete scope.selected.ccNumber ;
                            delete scope.selected.ccExpirationDate;
                            delete scope.selected.ccCvc;
                        }
                    };

                    scope.getPaymentMethod = function (paymentClass) {
                        return scope.paymentMethods ? scope.paymentMethods.find(paymentMethod => {
                            return paymentMethod.paymentClass === paymentClass;
                        }) : null;
                    };

                    scope.getPaymentMethodName = function (dpoItem) {
                        if (dpoItem.shortDescription[scope.lang]) {
                            return dpoItem.shortDescription[scope.lang];
                        } else {
                            if (dpoItem.paymentClass === "BANKTRANSFER") {
                                return gettextCatalog.getString("I'll pay by transfer");
                            }
                            if (dpoItem.paymentClass === "ATCHECKIN") {
                                return gettextCatalog.getString("I'll pay at checkin");
                            }
                            return gettextCatalog.getString("Credit card");
                        }
                    };

                    scope.ccedChangued =  function () {
                        if (scope.ccExpirationDate.year && scope.ccExpirationDate.month) {
                            scope.selected.ccExpirationDate = scope.ccExpirationDate.year + scope.ccExpirationDate.month;
                        }
                    };

                    scope.ccNumberChangued =  function () {
                        if (scope.selected.ccNumber != null) {
                            scope.selected.ccNumber = scope.selected.ccNumber.replace(/\D/g,'');
                        }
                    };

                    scope.getMethodsLength =  function () {
                        if (!scope.paymentMethods) return 0;
                        return Object.keys(scope.paymentMethods).length;
                    };

                }
            };
        }]);
