/*jslint node: true */
"use strict";
/*globals mod,angular, document, google */

/**
 * AUTOCOMPLETE
 *
 * Need '//maps.googleapis.com/maps/api/js?libraries=places' script
 *
 * <google-places item="item" />
 *
 **/

angular
    .module('google_places', [])
    .directive('googlePlaces', googlePlacesFun);

googlePlacesFun.$inject = ['$timeout'];

function googlePlacesFun($timeout) {
    const directive = {
        templateUrl: "templates/googlePlaces.html",
        link: link,
        restrict: 'E',
        scope: {
            item: '=',
            submitted: '='
        }
    };
    return directive;

    function link(scope, element, attrs) {
        scope.customer_data_form = scope.$parent.customer_data_form;
        scope.other = {
            route: '',
            street_number: '',
            piso: '',
            puerta: ''
        };

        scope.showAddress = 0;

        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            country: 'short_name',
            postal_code: 'short_name'
        };

        if (scope.gPlace === undefined) {
            var e = element[0].querySelector('.autocomplete');
            scope.gPlace = new google.maps.places.Autocomplete(e, {});
        }
        google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
            scope.resetInpAddress();
            var result = scope.gPlace.getPlace();
            if (result !== undefined) {
                if (result.address_components !== undefined) {
                    scope.$apply(function () {
                        var i;
                        for (i = 0; i < result.address_components.length; i++) {
                            var addressType = result.address_components[i].types[0];
                            var val = result.address_components[i][componentForm[addressType]];
                            if (addressType == 'postal_code') {
                                scope.item.zip = val;
                            } else if (addressType == 'locality') {
                                scope.item.city = val;
                            } else if (addressType == 'street_number') {
                                scope.other.street_number = val;
                            } else if (addressType == 'route') {
                                scope.other.route = val;
                            } else if (addressType == 'country') {
                                scope.item.country = val;
                            }
                        }
                        scope.createAddress();
                        document.getElementById('number').focus();
                        revisionAddress();
                    });
                }
            }
        });
        scope.blurAddress = function () {
            $timeout(revisionAddress, 300);
        };
        scope.resetInpAddress = function () {
            /* scope.item.country = ''; */
            scope.item.city = '';
            scope.other.route = '';
            scope.other.street_number = '';
            scope.item.zip = '';
        };
        function revisionAddress() {
            if (scope.item.country !== '' && scope.item.city !== '' && scope.other.route !== '' && scope.other.puerta !== '') {
                scope.showAddress = 2;
            } else {
                scope.showAddress = 1;
            }
        }

        scope.createAddress = function () {
            scope.item.address = '';
            var street = '';
            var street_number = '';
            var piso = '';
            var puerta = '';

            if (scope.other.route) {
                street = scope.other.route;
            }
            if (scope.other.street_number) {
                street_number = scope.other.street_number;
            }
            if (scope.other.piso) {
                piso = scope.other.piso;
            }
            if (scope.other.puerta) {
                puerta = '-' + scope.other.puerta;
            }
            scope.item.address = street + ' ' + street_number;
            if (piso || puerta) {
                scope.item.address += ', ' + piso + '' + puerta;
            }
        };
    }
}


