/*jslint node: true */
"use strict";
/*globals angular, globalConfig */

angular
    .module('budget_item', ['recursion_helper'])
    .directive('budgetItem', ['myConfig', 'RecursionHelper', function (myConfig, RecursionHelper) {
        return {
            // templateUrl: 'templates/budget_item.html',
            templateUrl: function () {
                const template = 'templates/budget_item.html';
                return globalConfig.production ?
                    `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                    template;
            },
            scope: {
                bgItem: '=',
                level: '='
            },
            restrict: 'A',
            replace: false,
            controller: ['$scope', function ($scope) {
                $scope.expand = function () {
                    $scope.bgItem.expanded = true;
                };
                $scope.collapse = function () {
                    $scope.bgItem.expanded = false;
                };

                $scope.units = function (u) {
                    if (!u) return "";
                    if ((u.indexOf('N') >= 0) && (u.indexOf('P') >= 0)) {
                        return "🌙👤";
                    } else if (u.indexOf('N') >= 0) {
                        return "🌙";
                    } else if (u.indexOf('P') >= 0) {
                        return "👤";
                    } else {
                        return "";
                    }
                };
            }],
            compile: function (element) {
                return RecursionHelper.compile(element);
            }
        };
    }]);
