/*jslint node: true */
"use strict";
/*globals angular, alert */

angular.module('birth_day_picker', [])

    .directive('birthDayPicker', ['gettextCatalog', function (gettextCatalog) {
        return {
            restrict: 'A',
            replace: true,
            require: '^ngModel',
            scope: {
                selectedDate: '=ngModel'
            },
            controller: ['$scope', function ($scope) {

                var now = new Date();
                $scope.dateFields = {
                    year: '',
                    month: '',
                    day: ''
                };

                var i;
                $scope.years = [];

                //var currentYear = now.getFullYear() - 18;
                var currentYear = now.getFullYear();
                var oldestYear = currentYear - 100;
                for (i = currentYear; i >= oldestYear; i--) {
                    $scope.years.push(i);
                }
                var monthNames = [
                    gettextCatalog.getString('January'),
                    gettextCatalog.getString('February'),
                    gettextCatalog.getString('March'),
                    gettextCatalog.getString('April'),
                    gettextCatalog.getString('May'),
                    gettextCatalog.getString('June'),
                    gettextCatalog.getString('July'),
                    gettextCatalog.getString('August'),
                    gettextCatalog.getString('September'),
                    gettextCatalog.getString('October'),
                    gettextCatalog.getString('November'),
                    gettextCatalog.getString('December')
                ];
                $scope.months = [];
                for (i = 0; i <= monthNames.length - 1; i++) {
                    $scope.months.push({value: i, name: monthNames[i]});
                }
                $scope.days = [];
                $scope.fillDays = function () {
                    $scope.days = [];
                    for (var k = 1; k <= 31; k++) {
                        if (k <= 28) {
                            $scope.days.push(k);
                        }
                        if ((k === 29) && (($scope.dateFields.month !== 1) || ($scope.dateFields.year % 4 === 0))) {
                            $scope.days.push(k);
                        }
                        if ((k === 30) && ($scope.dateFields.month != 1)) {
                            $scope.days.push(k);
                        }
                        if ((k === 31) && ([0, 2, 4, 6, 7, 9, 11].indexOf($scope.dateFields.month) > -1)) {
                            $scope.days.push(k);
                        }
                    }
                };

                $scope.fillDays();

            }],
            link: function (scope, element, attrs, ngModel) {
                var minAge = 0;

                if (attrs.minage) {
                    minAge = parseInt(attrs.minage);
                    scope.years.splice(0, minAge);
                }

                scope.labelDay = gettextCatalog.getString('Day');
                scope.labelMonth = gettextCatalog.getString('Month');
                scope.labelYear = gettextCatalog.getString('Year');

                scope.monthDisabled = true;
                scope.dayDisabled = true;
                element[0].children[0].style.cursor = 'pointer';
                element[0].children[1].style.cursor = 'not-allowed';
                element[0].children[2].style.cursor = 'not-allowed';

                if (ngModel && ngModel.$validators.required) {
                    ngModel.$validators.required = function () {
                        var validity = (ngModel.$modelValue && ngModel.$modelValue > 0);
                        if (validity) ngModel.$error = {};
                        else ngModel.$error = {required: true};
                        ngModel.$setValidity('required', validity);
                        return (scope.dateFields.day !== '' && scope.dateFields.month !== '' && scope.dateFields.year !== '');
                    };
                }

                scope.$watch('dateFields', function () {
                    if (scope.dateFields.year !== '') {
                        scope.monthDisabled = false;
                        element[0].children[1].style.cursor = 'pointer';
                        element[0].children[2].style.cursor = 'not-allowed';
                    }
                    else return;
                    if (scope.dateFields.month !== '') {
                        scope.dayDisabled = false;
                        element[0].children[2].style.cursor = 'not-allowed';
                        scope.fillDays();
                    } else return;
                    if (scope.dateFields.day !== '') {
                        scope.selectedDate = (Date.UTC(parseInt(scope.dateFields.year), parseInt(scope.dateFields.month), parseInt(scope.dateFields.day))) / 86400000;
                        var tmpDate = new Date(scope.selectedDate * 86400000);
                        scope.$parent.tempAGE = calculateAge(tmpDate);
                    }
                    element[0].children[1].style.cursor = 'pointer';
                    element[0].children[2].style.cursor = 'pointer';
                    if (scope.$parent.tempAGE < minAge) {
                        alert("A minor can not make a reservation");
                    }
                }, true);

                function calculateAge(birthday) {
                    var ageDifMs = Date.now() - birthday.getTime();
                    var ageDate = new Date(ageDifMs); // miliseconds from epoch
                    return Math.abs(ageDate.getUTCFullYear() - 1970);
                }

            },
            template: '<div class="form-inline">' +
            '    <select style="width: 29%" name="year" ng-model="dateFields.year" ng-change=fillDays() class="form-control" ng-options="year for year in years" ng-change="checkDate()" >' +
            '<option value="" disabled selected >{{ "Year" | translate }}</option></select>' +
            '    <select style="width: 42%" name="month" ng-model="dateFields.month" ng-change=fillDays() class="form-control" ng-options="month.value as month.name for month in months" value="{{ dateField.month }}" ng-disabled="monthDisabled">' +
            '<option value="" disabled selected>{{ "Month" | translate }}</option></select>' +
            '    <select style="width: 25%" name="day" ng-model="dateFields.day"class="form-control" ng-options="day for day in days" ng-change="checkDate()"  ng-disabled="dayDisabled">' +
            '<option value="" disabled selected>{{ "Day" | translate }}</option></select>' +
            '</div>'
        };
    }]);

