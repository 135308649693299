/*jslint node: true */
"use strict";
/*globals mod, masterUtils, _, angular, window, navigator, $ */

angular
    .module('booking_customer_data', ['select_country'])
    .controller('BookingCustomerDataCtrl', ['$scope', 'gettextCatalog', 'myConfig',
        function ($scope, gettextCatalog, myConfig) {

            $scope.accompanyingRequired = myConfig.accompanyingRequired;

            $scope.range = function (min, max, step) {
                step = step || 1;
                const input = [];
                for (let i = min; i <= max; i += step) input.push(i);
                return input;
            };

            $scope.lastNameDirty = function (item) {
                if (myConfig.accompanyingRequired) {
                    return ($scope.customer_data_form['lastName_' + item].$invalid);
                } else {
                    if (!$scope.booking.accompanyingPersons[item]) {
                        return false;
                    }
                    if (!$scope.booking.accompanyingPersons[item].firstName && !$scope.booking.accompanyingPersons[item].birthdate) {
                        return false;
                    }
                    return ($scope.customer_data_form['lastName_' + item].$invalid);
                }
            };

            $scope.birthdateDirty = function (item) {
                return ($scope.customer_data_form['birthdate_' + item].$invalid);
            };

        }
    ]);

