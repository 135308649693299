"use strict";
/*jslint node: true, esversion: 8 */
/* globals $, angular, document, window, aliceonboarding, URL, Promise */

angular.module('aliceBiometrics', [])
    .factory('AliceBiometrics', ['$http', 'myConfig', 'alertsService', function ($http, myConfig, alertsService) {

        const aliceBiometricsDivName = 'alice-onboarding-mount';
        let isOperative = false;
        let promise = null;
        let fFresolve = () => null;

        function initAliceContainer() {
            const contentAlice = document.createElement('div');
            contentAlice.id = aliceBiometricsDivName;
            contentAlice.style.position = 'fixed';
            contentAlice.style.width = '100%';
            contentAlice.style.height = '100%';
            contentAlice.style.top = '0';
            contentAlice.style.left = '0';
            contentAlice.style.zIndex = '10000000';

            contentAlice.style.cssText = 'position:fixed;width:100%;height:100%;top:0;left:0;z-index:100000;';
            document.body.appendChild(contentAlice);
        }

        function launchAliceBiometricsOnBoarding(userToken, documentsConfig) {

            const url = new URL(window.location.href);
            const lang = url.searchParams.get('lang');

            aliceonboarding.Onboarding.setEnvironment('sandbox');

            let documentStageConfig = new aliceonboarding.DocumentStageConfig({
                capturerType: aliceonboarding.DocumentCapturerType.FILE,
                //  automaticCapture: true,
                cameraType: aliceonboarding.CameraType.BACK
            });


            const acceptedDocumentTypes = [aliceonboarding.DocumentType.IDCARD, aliceonboarding.DocumentType.PASSPORT];
            let config = new aliceonboarding.OnboardingConfig()
                .withUserToken(userToken)
                .withCustomLocalization({language: lang});


            if (documentsConfig === "D" || documentsConfig === "DyP") {
                config = config.withAddDocumentStage({
                    documentType: aliceonboarding.DocumentType.IDCARD,
                    documentStageConfig: documentStageConfig
                });
            }

            if (documentsConfig === "P" || documentsConfig === "DyP") {
                config = config.withAddDocumentStage({
                    documentType: aliceonboarding.DocumentType.PASSPORT,
                    documentStageConfig: documentStageConfig
                });
            }
            if (documentsConfig === "DoP") {
                config = config.withAddAnyDocumentStage({
                    allowedTypes: acceptedDocumentTypes,
                    documentStageConfig: documentStageConfig
                });
            }

            const onboarding = new aliceonboarding.Onboarding({
                idSelector: aliceBiometricsDivName,
                onboardingConfig: config
            });
            // eslint-disable-next-line no-unused-vars
            const self = this;
            onboarding.run(
                (onSuccessONBoarding).bind(self),
                (onErrorOnBoarding).bind(self),
                (onExitOnBoarding).bind(self)
            );
        }

        function onSuccessONBoarding(response) {
            setAliceBiometricsButtonInLoadingState(false);
            fFresolve(true);
            isOperative = false;
            removeAliceContainer();
        }

        function onErrorOnBoarding(response) {
            setAliceBiometricsButtonInLoadingState(false);
            fFresolve(false);
            isOperative = false;
            removeAliceContainer();
        }

        function onExitOnBoarding(response) {
            setAliceBiometricsButtonInLoadingState(false);
            isOperative = false;
            fFresolve(false);
            removeAliceContainer();
        }

        async function getAliceBiometricsDocumentsConfig() {
            try {
                const response = await $http.get(myConfig.host + "/api/aliceBiometrics/documentsConfig");
                // return response.data?.data || "D";
                return response.data ? response.data.data : "D";
            } catch (err) {
                alertsService.newError('Error', err.message || err.errorMsg || err.data.message || err.data.errorMsg);
            }
        }

        function removeAliceContainer() {
            const aliceContainer = document.getElementById(aliceBiometricsDivName);
            if (aliceContainer) {
                aliceContainer.remove();
            }
        }

        function setAliceBiometricsButtonInLoadingState(isLoading) {
            const aliceBiometricsButton = document.getElementsByClassName('alice-biometrics-button-button');
            Array.from(aliceBiometricsButton).forEach((aliceBiometricsButton) => {
                if (aliceBiometricsButton) {
                    if (isLoading) {
                        aliceBiometricsButton.disabled = true;
                        aliceBiometricsButton.classList.add('loading-alice-biometrics');
                    } else {
                        aliceBiometricsButton.disabled = false;
                        aliceBiometricsButton.classList.remove('loading-alice-biometrics');
                    }
                }
            });

        }

        return {
            initAliceBiometricsOnBoarding_Holder: async function (cryptedIdBooking) {
                if (isOperative) {
                    return; //nothign to do
                }
                setAliceBiometricsButtonInLoadingState(true);
                isOperative = true;
                const body = {
                    cryptedIdBooking: cryptedIdBooking
                };

                const documentsConfig = await getAliceBiometricsDocumentsConfig();
                try {
                    const response = await $http.post(myConfig.host + "/api/aliceBiometrics/userToken", body);
                    const {userToken} = response.data;
                    promise = new Promise((resolve, reject) => {
                        fFresolve = resolve;
                    });
                    initAliceContainer();
                    launchAliceBiometricsOnBoarding(userToken, documentsConfig);

                } catch (err) {
                    setAliceBiometricsButtonInLoadingState(false);
                    isOperative = false;
                    alertsService.newError('Error', err.message || err.errorMsg || err.data.message || err.data.errorMsg);
                }
            },
          initAliceBiometricsOnBoarding_Companion: async function (cryptedIdBooking,isNew,companionId,document,aliceBiometricsUuid,existingAliceBiometricsUuidInOtherCompanions){
                if (isOperative) {
                    return; //nothign to do
                }
                setAliceBiometricsButtonInLoadingState(true);
                isOperative = true;
                const body = {
                    cryptedIdBooking: cryptedIdBooking,
                  document:document,
                  companionId:companionId,
                  isNew:isNew,
                  aliceBiometricsUuid:aliceBiometricsUuid,
                  existingAliceBiometricsUuidInOtherCompanions:existingAliceBiometricsUuidInOtherCompanions
              };
                const documentsConfig = await getAliceBiometricsDocumentsConfig();
                try {
                    const response = await $http.post(myConfig.host + "/api/aliceBiometrics/companionToken", body);
                  const {userToken, aliceBiometricsUuid} = response.data;
                    promise = new Promise((resolve, reject) => {
                      fFresolve = (isSuccess)=>resolve({isSuccess,aliceBiometricsUuid});
                    });
                    initAliceContainer();
                    launchAliceBiometricsOnBoarding(userToken, documentsConfig);

                } catch (err) {
                    setAliceBiometricsButtonInLoadingState(false);
                    isOperative = false;
                    alertsService.newError('Error', err.message || err.errorMsg || err.data.message || err.data.errorMsg);
                }
            },
            getDataFromAliceBiometrics_holder: async function (cryptedIdBooking) {

                const body = {
                    cryptedIdBooking: cryptedIdBooking
              };

                try {
                    const response = await $http.post(myConfig.host + "/api/aliceBiometrics/retrieveUserData", body);
                    return response.data;

                } catch (err) {
                    alertsService.newError('Error', err.message || err.errorMsg || err.data.message || err.data.errorMsg);
                }
            },
          getDataFromAliceBiometrics_companion:async function(cryptedIdBooking,aliceBiometricsUuid){
                const body = {
                    cryptedIdBooking: cryptedIdBooking,
                    aliceBiometricsUuid: aliceBiometricsUuid
                };

                try {
                    const response = await $http.post(myConfig.host + "/api/aliceBiometrics/retrieveCompanionData", body);
                    return response.data;

                } catch (err) {
                    alertsService.newError('Error', err.message || err.errorMsg || err.data.message || err.data.errorMsg);
                }
            },
            getPromise: () => promise


        };

    }]);
