/*jslint node: true */
"use strict";
/*globals angular */

angular
    .module('magnific_popup', [])
    .directive('magnificPopup', ['$rootScope', function ($rootScope) {
        return {
            restrict: 'AE',
            replace: true,
            scope: {
                images: '=',
                options: '='
            },
            link: function (scope, element, attrs) {

                scope.popImages = [];

                scope.images.forEach(function (img, index) {
                    var tmpImg = angular.copy(img);
                    tmpImg.src = $rootScope.getImageUrl(img.idImage, false, "lg");
                    tmpImg.type = 'image';
                    tmpImg.index = index;
                    if (img.title[$rootScope.lang]) {
                        tmpImg.title = img.title[$rootScope.lang] ? img.title[$rootScope.lang] : img.title;
                    }
                    scope.popImages.push(tmpImg);
                });

                element.bind('click', function (event) {
                    element.magnificPopup({
                        items: scope.popImages,
                        gallery: {
                            enabled: true,
                            preload: [0, 1]
                        },
                        type: 'image',
                        index: parseInt(event.currentTarget.getAttribute("index"))
                    }).magnificPopup('open');
                    event.preventDefault();
                });
            }
        };

    }]);