/*jslint node: true */
"use strict";
/*globals angular */

angular
    .module('popups', ['ngDialog'])
    .directive('popupConditions', ['ngDialog', function (ngDialog) {
        return {
            restrict: 'A',
            scope:{
                content: '='
            },
            link: function (scope, element, attrs) {
                element.on('click', function() {
                    var bookingConditions = scope.content;
                    ngDialog.open({
                        template:   '<div id="bookingConditions" ng-if="bookingConditions !== \'\'">'+
                                        '<div class="pack_titulo_texto_wrapper">'+
                                            '<h1 translate>Conditions</h1>'+
                                            '<div class="pack_texto_wrapper">'+
                                                '<div ng-bind-html="bookingConditions | trust"></div>'+
                                                '<br>'+
                                                '<div id="confirmacion_reserva_imprimir" class="do-not-print">'+
                                                   '<input class="reservas_imprimir" type="button" value="{{\'Print\' | translate}}" ng-click="printConditions()" style=\'padding: 10px 30px;\'>'+
                                                '</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>',
                        plain: true,
                        className: 'ngdialog-theme-plain largeDialog padding50',
                        controller: ["$scope", "$window", "gettextCatalog", function ($scope, $window, gettextCatalog) {
                            $scope.bookingConditions = bookingConditions;
                            $scope.printConditions = function () {
                                var conditionElement = angular.element("#bookingConditions");
                                conditionElement.find("#confirmacion_reserva_imprimir").hide();
                                var mywindow = $window.open('', 'my div', 'height=800,width=600');
                                mywindow.document.write('<html><head><title>' + gettextCatalog.getString("Conditions") + '</title>');
                                mywindow.document.write('</head><body >');
                                mywindow.document.write(conditionElement.html());
                                conditionElement.find("#confirmacion_reserva_imprimir").show();
                                mywindow.document.write('</body></html>');
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10
                                mywindow.print();
                                mywindow.close();
                            };
                        }]
                    });
                });
            }
        };
    }])
    .directive('popupPrivacy', ['ngDialog', function (ngDialog) {
        return {
            restrict: 'A',
            scope:{
                content: '='
            },
            link: function (scope, element, attrs) {
                element.on('click', function() {
                    var privacyPolicy = scope.content;
                    ngDialog.open({
                        template:   '<div id="privacyPolicy" ng-if="privacyPolicy !== \'\'">'+
                                        '<div class="pack_titulo_texto_wrapper">'+
                                            '<h1 translate>Privacy Policy</h1>'+
                                            '<div class="pack_texto_wrapper">'+
                                                '<div ng-bind-html="privacyPolicy | trust"></div>'+
                                                '<br>'+
                                                '<div id="confirmacion_reserva_imprimir" class="do-not-print">'+
                                                    '<input class="reservas_imprimir" type="button" value="{{\'Print\' | translate}}" ng-click="printPrivacyPolicy()" style="padding: 10px 30px;">'+
                                                '</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>',
                        plain: true,
                        className: 'ngdialog-theme-plain largeDialog padding50',
                        controller: ["$scope", "$window", "gettextCatalog", function ($scope, $window, gettextCatalog) {
                            $scope.privacyPolicy = privacyPolicy;
                            $scope.printPrivacyPolicy = function () {
                                var privacyElement = angular.element("#privacyPolicy");
                                privacyElement.find("#confirmacion_reserva_imprimir").hide();
                                var mywindow = $window.open('', 'my div', 'height=800,width=600');
                                mywindow.document.write('<html><head><title>' + gettextCatalog.getString("Privacy Policy") + '</title>');
                                mywindow.document.write('</head><body >');
                                mywindow.document.write(privacyElement.html());
                                privacyElement.find("#confirmacion_reserva_imprimir").show();
                                mywindow.document.write('</body></html>');
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10
                                mywindow.print();
                                mywindow.close();
                            };
                        }]
                    });
                });
            }
        };
    }])
    .directive('popupPrint', ['ngDialog', function (ngDialog) {
        return {
            restrict: 'A',
            scope:{
                pcontent: '=',
                ptitle: '='
            },
            link: function (scope, element, attrs) {
                element.on('click', function() {
                    var privacyPolicy = scope.pcontent;

                    var template = '<div id="privacyPolicy" ng-if="privacyPolicy !== \'\'">'+
                        '<div class="pack_titulo_texto_wrapper">'+
                        '<h1 translate>'+scope.ptitle+'</h1>'+
                        '<div class="pack_texto_wrapper">'+
                        '<div ng-bind-html="privacyPolicy | trust"></div>'+
                        '<br>'+
                        '<div id="confirmacion_reserva_imprimir" class="do-not-print">'+
                        '<input class="reservas_imprimir" type="button" value="{{\'Print\' | translate}}" ng-click="printPrivacyPolicy()" style="padding: 10px 30px;">'+
                        '</div>'+
                        '</div>'+
                        '</div>'+
                        '</div>';

                    ngDialog.open({
                        template:   template,
                        plain: true,
                        className: 'ngdialog-theme-plain largeDialog padding50',
                        controller: ["$scope", "$window", "gettextCatalog", function ($scope, $window) {
                            $scope.privacyPolicy = privacyPolicy;
                            $scope.printPrivacyPolicy = function () {
                                var privacyElement = angular.element("#privacyPolicy");
                                privacyElement.find("#confirmacion_reserva_imprimir").hide();
                                var mywindow = $window.open('', 'my div', 'height=800,width=600');
                                mywindow.document.write('<html><head><title>' + scope.ptitle + '</title>');
                                mywindow.document.write('</head><body >');
                                mywindow.document.write(privacyElement.html());
                                privacyElement.find("#confirmacion_reserva_imprimir").show();
                                mywindow.document.write('</body></html>');
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10
                                mywindow.print();
                                mywindow.close();
                            };
                        }]
                    });
                });
            }
        };
    }])
    .directive('popupCvc', ['ngDialog', function (ngDialog) {
        return {
            restrict: 'A',
            scope: {
                content: '='
            },
            link: function (scope, element, attrs) {
                element.addClass('cursorHelp');
                element.on('click', function () {
                    ngDialog.open({
                        template:   '<div id="cvc_explicacion" style="box-shadow:none">'+
                                        '<img id="cvc-img" src="static/img/cvc-visa.gif" width="170">'+
                                        '<p translate>The CVC is an additional code printed in the debit or credit card that provides a greater security to the use of the card in the Internet. It consists of 3 or 4 digits, which are not part of the card number.</p>'+
                                        '<br>'+
                                        '<select class="info_tarjeta" onchange="document.getElementById(\'cvc-img\').setAttribute(\'src\',\'static/img/cvc-\' + this.options[this.selectedIndex].value+\'.gif\' )">'+
                                            '<option value="visa">VISA</option>'+
                                            '<option value="mc">MasterCard</option>'+
                                            '<option value="amex">American Express</option>'+
                                        '</select>'+
                                    '</div>',
                        plain: true,
                        className: 'ngdialog-theme-plain smallDialog padding50'
                    });
                });
            }
        };
    }]);
