/*jslint node: true */
"use strict";
/*globals angular, document, ga, window, fbq, dataLayer, gtag, TextEncoder, crypto, globalConfig */

angular
    .module('booking', [])
    .config(BookingConfig)
    .controller('BookingCtrl', BookingCtrl);

BookingConfig.$inject = ['$stateProvider'];

function BookingConfig($stateProvider) {
    $stateProvider.state('booking', {
        url: '/booking?cryptedIdBooking&lang&ra&trackref&guid&payment',
        views: {
            content: {
                // templateUrl: 'templates/booking.html',
                templateUrl: function () {
                    const template = 'templates/booking.html';
                    return globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                        template;
                },
                controller: 'BookingCtrl'
            }
        }
    });
}

BookingCtrl.$inject = ['$scope', 'Bookings', '$window', '$state', 'myConfig', 'alertsService', 'gettextCatalog', '$location', '$cookies'];

function sha256(text) {
    return new Promise(function (resolve, reject) {
        const data = new TextEncoder().encode(text);
        crypto.subtle.digest('SHA-256', data).then(function (hashBuffer) {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            resolve(hashHex);
        });
    });
}

function BookingCtrl($scope, Bookings, $window, $state, myConfig, alertsService, gettextCatalog, $location, $cookies) {

    $window.scrollTo(0, 0);
    $scope.checkinActive = false;
    $scope.returnUrl = myConfig.returnUrl;

    if (!$state.params.cryptedIdBooking) {
        $state.go('search');
    }
    $scope.cryptedIdBooking = $state.params.cryptedIdBooking;
    const viewDocFrame = document.getElementById("viewDocFrame");
    let template = '';

    let info = null;

    Bookings.getBooking($scope.cryptedIdBooking)
        .then(function (bookingData) {
            info = bookingData;
            return Bookings.getInfo();
        })
        .then(function (infoData) {

        $scope.checkinActive = info.expressChecking;
        $scope.showDaysBeforeBooking = info.showDaysBeforeBooking;

        $scope.showAutoCheckInButton =
            info.expressChecking == 'Si' &&
            info.showDaysBeforeBooking &&
            info.disableButtonOnBookingStep !== true;

        if (info.bookingStatus === 'PAYMENT_PENDING' && info.enablePreReservations === false) {
            $scope.showAutoCheckInButton = false;
        }

        if (info.BottomBoxEndBooking != null) {
            $scope.BottomBoxEndBooking = info.BottomBoxEndBooking;
        }
        if (info.productBookingTemplate) {
            template = info.productBookingTemplate;
            var docSrc = myConfig.host + '/api/docs/' + info.productBookingTemplate + '/' + $scope.cryptedIdBooking + "?idProperty=" + myConfig.idProperty;
            if ($state.params.lang) docSrc += "&lang=" + $state.params.lang;
            viewDocFrame.src = docSrc;
        } else {
            alertsService.newError('Error', gettextCatalog.getString("This product does haven't a template"));
        }

        if (myConfig.trackingId && typeof ga !== "undefined") {
            ga('send', 'pageview', {page: "/booking?cryptedIdBooking=" + $scope.cryptedIdBooking});
        }

        if (myConfig.googleGlobalTag && typeof gtag !== "undefined") {
            gtag('event', 'page_view', {
                page_path: "/booking?cryptedIdBooking=" + $scope.cryptedIdBooking,
                // send_to: myConfig.googleGlobalTag
            });
        }

        $location.search('payment', null);


        let booking_ecommerce_send = $cookies.get('booking_ecommerce_send');

        let price = $cookies.get('booking_price');
        let tax = $cookies.get('booking_tax');

        const enhanced_conversion_data = {
            email: info.reservationHolder.email,
            home_address: {}
        };
        if (info.reservationHolder.firstName) {
            enhanced_conversion_data.first_name = info.reservationHolder.firstName;
        }
        if (info.reservationHolder.lastName) {
            enhanced_conversion_data.last_name = info.reservationHolder.lastName;
        }
        if (info.reservationHolder.phone) {
            enhanced_conversion_data.phone_number = info.reservationHolder.phone;
        }
        if (info.reservationHolder.address) {
            enhanced_conversion_data.home_address.street = info.reservationHolder.address;
        }
        if (info.reservationHolder.city) {
            enhanced_conversion_data.home_address.city = info.reservationHolder.city;
        }
        if (info.reservationHolder.zip) {
            enhanced_conversion_data.home_address.postal_code = info.reservationHolder.zip;
        }
        if (info.reservationHolder.country) {
            enhanced_conversion_data.home_address.country = info.reservationHolder.country;
        }
        //         home_address: {
        //           region: 'CA', ¿?
        //         }

        function reemplazarCaracteresNoLatinos(texto) {
            return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }

        for (const key in enhanced_conversion_data) {
            if (key === 'home_address' && enhanced_conversion_data[key] != null) {
                for (const key2 in enhanced_conversion_data[key]) {
                    enhanced_conversion_data[key][key2] = enhanced_conversion_data[key][key2].toString();
                    enhanced_conversion_data[key][key2] = reemplazarCaracteresNoLatinos(enhanced_conversion_data[key][key2]);
                }
            } else {
                enhanced_conversion_data[key] = enhanced_conversion_data[key].toString();
                enhanced_conversion_data[key] = reemplazarCaracteresNoLatinos(enhanced_conversion_data[key]);
            }
            if (['email', 'phone'].includes(key)) {
                if (enhanced_conversion_data[key] != null) {
                    enhanced_conversion_data[key] = enhanced_conversion_data[key].replace(/\s/g, '').toLowerCase();
                }
            }
        }

        if (price != null && tax != null && booking_ecommerce_send === 'false') {

            // const price = (reservationTotalAmount / 1.1).toFixed(2);
            // const tax = (reservationTotalAmount / 1.1) * (0.1).toFixed(2);

            if (myConfig.trackingId && typeof ga !== "undefined") {
                ga('require', 'ecommerce');
                ga('ecommerce:addTransaction', {
                    'id': info.idBooking,               // Transaction ID. Required.
                    affiliation: info.propertyName,
                    'revenue': price,                   // Grand Total.
                    'tax': tax,
                    'currency': 'EUR'
                });
                ga('ecommerce:addItem', {
                    'id': info.idProduct,
                    'sku': info.idProduct,                       // SKU/code.
                    'name': info.productName,                    // Product name. Required.
                    'category': info.webVisualizationGroupName,  // Category or variation.
                    'price': price,                              // Unit price.
                    'quantity': '1',
                    'currency': 'EUR'
                });
                ga('ecommerce:send');
            }

            if (myConfig.googleGlobalTag && typeof gtag !== "undefined") {
                gtag('event', 'purchase', {
                    transaction_id: info.idBooking,
                    affiliation: info.propertyName,
                    currency: 'EUR',
                    value: price,
                    tax: tax,
                    items: [
                        {
                            id: info.idProduct,
                            name: info.productName,
                            item_id: info.idProduct,
                            item_name: info.productName,
                            affiliation: info.propertyName,
                            category: info.webVisualizationGroupName,
                            currency: 'EUR',
                            price: price,
                            quantity: 1
                        }
                    ]
                });
            }

            if (myConfig.facebookPixelCode && typeof fbq !== "undefined") {
                fbq('track', 'Purchase', {
                    value: price,
                    currency: 'EUR',
                    content_name: info.productName,
                    content_ids: [info.idProduct, info.idBooking],
                    content_type: info.webVisualizationGroupName,
                });
            }

            if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {

                if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                    dataLayer.push({
                        'event': 'transaction',
                        'ecommerce': {
                            'purchase': {
                                'actionField': {
                                    'id': info.idBooking,                  // Transaction ID. Required for purchases and refunds.
                                    'affiliation': info.propertyName,
                                    'revenue': price,  // Total transaction value (incl. tax and shipping)
                                    'tax': tax,
                                    'currency': 'EUR'
                                },
                                'products': [{                             // List of productFieldObjects.
                                    'name': info.productName,              // Name or ID is required.
                                    'id': info.idProduct,
                                    'price': price,
                                    'category': info.webVisualizationGroupName,
                                    'quantity': 1,                          // Optional fields may be omitted or set to empty string.
                                    'currency': 'EUR'
                                }]
                            }
                        }
                    });
                }

                const extraGtmData = {
                    price, tax,
                    item_name: info.productName,
                    item_brand:  info.webVisualizationGroupName,
                };
                if (info.promotionId != null) {
                    extraGtmData.promotion_id = info.promotionCode != null ? info.promotionCode.replace(/\s/g, '_') : null;
                    extraGtmData.promotion_name = info.promotionName || `Promotion ${info.promotionId}`;
                }

                const mainProduct = infoData.products.find(p => p.idProduct == info.idProduct);
                const product = Object.assign({}, mainProduct, {facilityIds: info.facilityIds});

                const ecommerceData = {
                    currency: 'EUR',
                    transaction_id: info.idBooking,
                    affiliation: info.propertyName,
                    tax: parseFloat(tax),
                    value: parseFloat(price),
                    items: [Bookings.processGtmProduct(product, null, extraGtmData)]
                };

                if (myConfig.GoogleTagManagerVersion == 2 && !myConfig.purchase_enhanced_conversion) {
                    dataLayer.push({ecommerce: null});
                    dataLayer.push({
                        event: "purchase",
                        ecommerce: ecommerceData
                    });
                }

                if (myConfig.purchase_enhanced_conversion) {
                    Promise.all([
                        enhanced_conversion_data.email != null ? sha256(enhanced_conversion_data.email) : null,
                        enhanced_conversion_data.phone_number != null ? sha256(enhanced_conversion_data.phone_number) : null,
                        enhanced_conversion_data.first_name != null ? sha256(enhanced_conversion_data.first_name) : null,
                        enhanced_conversion_data.last_name != null ? sha256(enhanced_conversion_data.last_name) : null
                    ])
                        .then(function (values) {
                            enhanced_conversion_data.sha256_email_address = values[0];
                            enhanced_conversion_data.sha256_phone_number = values[1];
                            enhanced_conversion_data.sha256_first_name = values[2];
                            enhanced_conversion_data.sha256_last_name = values[3];

                            if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                                dataLayer.push({
                                    event: 'purchase_enhanced_conversion',
                                    order_value: price,
                                    order_id: info.idBooking,
                                    currencyCode: 'EUR',
                                    enhanced_conversion_data
                                });
                            }
                            if (myConfig.GoogleTagManagerVersion == 2) {
                                dataLayer.push({ecommerce: null});
                                dataLayer.push({
                                    event: 'purchase',
                                    ecommerce: Object.assign(ecommerceData, enhanced_conversion_data)
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                }

                if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                    dataLayer.push({
                        event: 'bookingCompleted',
                        propertyName: info.propertyName,
                        productName: info.productName,
                        productGroupName: info.webVisualizationGroupName,
                        bookingLanguage: info.lang,
                        idBooking: info.idBooking,
                        price,
                        tax
                    });
                }
            }

            if (window._loq) {
                window._loq.push(["tag", "Booking Finished", true]);
                window._loq.push(["tag", info.idBooking]);
            }

            $cookies.remove('booking_ecommerce_send');

        }

    });

    /*
    // BUG: srcdoc no compatible amb IE
    Bookings.getDoc('booking', $scope.cryptedIdBooking).then(function (response) {
        document.getElementById("viewDocFrame").srcdoc = response;
        loadingService.disable('principal');
    }, function (err) {
       loadingService.disable('principal');
    });
    */

    $scope.printBooking = function () {
        var contentWindow = viewDocFrame.contentWindow;
        //contentWindow.focus();
        contentWindow.print();
    };

    $scope.openPDF = function () {
        if (template) {
            var docSrc = myConfig.host + "/api/docs/" + template + "/" + $scope.cryptedIdBooking + "?mode=pdf&idProperty=" + myConfig.idProperty;
            if ($state.params.lang) docSrc += "&lang=" + $state.params.lang;
            var printWindow = $window.open(
                docSrc,
                "name='Print version'",
                'left=0, top=0, width=750, height=900, toolbar=0, resizable=0'
            );
        } else {
            alertsService.new('bookingTemplate', 'error', gettextCatalog.getString("This product does haven't a template"));
        }
    };

}
