angular.module('myaccount', [])
    .factory('MyAccount', ['$location', 'myConfig',
        function ($location, myConfig) {

            let tenantId = null;
            let myAccountTokenString = localStorage.getItem("myaccount:session");
            if (myAccountTokenString == null && localStorage.getItem("moncompte:session") != null) {
                myAccountTokenString = localStorage.getItem("moncompte:session");
            }
            let myAccountToken = myAccountTokenString != null ? JSON.parse(myAccountTokenString) : null;
            let myAccountPersonId = null;
            let myAccountPerson = null;
            let myAccountUrl = 'https://my-account.mastercamping.com';
            let showButtonEnabled = false;
            let scriptLoaded = false;
            if (myAccountTokenString != null) {
                showButtonEnabled = true;
            }
            let automaticPromotionChecked = false;

            window.addEventListener('myaccount-login', function (e) {
                console.log('myaccount-login', e.detail);
                const data = e.detail;
                tenantId = data.tenantId;
                myAccountToken = data.session;
                loadAccount().finally();
            });

            window.addEventListener('myaccount-logout', function (e) {
                console.log('myaccount-logout', e.detail);
                myAccountToken = null;
                myAccountPerson = null;
                myAccountPersonId = null;
                localStorage.removeItem("moncompte:session");
                localStorage.removeItem("myaccount:session");
                localStorage.removeItem("moncompte:person");
                localStorage.removeItem("myaccount:person");
                PromotionService.checkAvailablePromotions();
            });

            function loadAccount() {
                return new Promise((resolve) => {
                    if (myAccountToken != null) {
                        const accountUrl = `${myAccountUrl}/rest/account`;
                        fetch(accountUrl, {
                            method: 'GET',
                            headers: {
                                'X-Tenant-Id': tenantId,
                                'X-Session-Id': myAccountToken.session,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                myAccountPerson = data.person;
                                myAccountPersonId = data.customerId;
                                localStorage.setItem("moncompte:person", JSON.stringify(data.person));
                                localStorage.setItem("myaccount:person", JSON.stringify(data.person));
                                loadScript(tenantId);
                                resolve(data);
                            })
                            .catch(error => {
                                console.log('Error:', error.toString());
                                resolve();
                            });
                    } else {
                        loadScript(tenantId);
                        resolve();
                    }
                });
            }

            function loadScript(tenantIdentifier) {
                if (scriptLoaded) {
                    return;
                }
                if (tenantIdentifier == null) {
                    return;
                }

                document.body.classList.add('my-account');

                const myAccountBox = document.createElement('div');
                myAccountBox.id = 'my-account-box';
                myAccountBox.style.display = 'none';
                const floatingRightBox = document.getElementById('floating-right-box');
                floatingRightBox.insertBefore(myAccountBox, floatingRightBox.firstChild);

                const scriptElement = document.createElement('script');
                scriptElement.src = `https://my-account.mastercamping.com/static/js/main.js`;
                document.body.appendChild(scriptElement);
                scriptElement.onload = () => {
                    scriptLoaded = true;
                    const host = window.location.origin;
                    const myAccount = document.createElement('my-account');
                    myAccount.setAttribute('tenant-id', tenantId);
                    myAccount.setAttribute('hide-new-booking-button', 'false');
                    myAccount.setAttribute('url-new-booking', `${host}/search`);
                    myAccount.setAttribute('url-checkin', `${host}/checkin`);
                    myAccount.setAttribute('custom-style', `/static/css/my_account.css`);
                    myAccount.setAttribute('language', myConfig.lang);
                    const myAccountBox = document.getElementById('my-account-box');
                    myAccountBox.appendChild(myAccount);
                    if (showButtonEnabled) {
                        showButton();
                    }
                };
            }

            function showButton() {
                const monCompteBox = document.getElementById('my-account-box');
                if (monCompteBox != null) {
                    setTimeout(() => {
                        monCompteBox.style.display = 'block';
                    }, 500);
                }
            }

            return {
                getMyAccountPersonId: () => myAccountPersonId,
                getPerson: () => myAccountPerson,
                setMyAccountBase64Token: function (session) {
                    try {
                        const myAccountTokenFromBase64 = atob(session);
                        localStorage.setItem("moncompte:session", myAccountTokenFromBase64);
                        localStorage.setItem("myaccount:session", myAccountTokenFromBase64);
                        myAccountToken = JSON.parse(myAccountTokenFromBase64);
                    } catch (error) {
                        console.log("error", error);
                    }
                },
                loadScript,
                loadMyAccount: function (tenantIdentifier, showButtonTrigger = false) {
                    showButtonEnabled = showButtonTrigger;
                    return new Promise((resolve) => {
                        if (tenantIdentifier == null) {
                            return;
                        }
                        tenantId = tenantIdentifier;
                        const myAccountToken = $location.search().myAccountToken;
                        if (myAccountToken != null) {
                            this.setMyAccountBase64Token(myAccountToken);
                            $location.search('myAccountToken', null);
                            showButtonEnabled = true;
                        }
                        const monCompteToken = $location.search().monCompteToken;
                        if (monCompteToken != null) {
                            this.setMyAccountBase64Token(monCompteToken);
                            $location.search('monCompteToken', null);
                            showButtonEnabled = true;
                        }
                        loadAccount()
                            .then((data) => {
                                resolve(data);
                            })
                            .catch((err) => {
                                console.warn("Error loading my account", err.toString());
                                resolve();
                            });

                    });
                },
                loadAccount,
                getFormattedData: function () {
                    if (myAccountPerson == null) {
                        return null;
                    }
                    const splittedLastname = myAccountPerson.lastName.split(' ');
                    const lastName = splittedLastname.shift();
                    const secondLastName = splittedLastname.join(' ');
                    return {
                        idClientGroup: myAccountPersonId,
                        firstName: myAccountPerson.firstName,
                        lastName: lastName,
                        secondLastName: secondLastName,
                        email: myAccountPerson.email,
                        phone: myAccountPerson.phone,
                        cellPhone: myAccountPerson.mobilePhone,
                        address: myAccountPerson.address1 + (myAccountPerson.address2 != null ? ' ' + myAccountPerson.address2 : ''),
                        zip: myAccountPerson.postCode,
                        city: myAccountPerson.city,
                        country: myAccountPerson.country,
                        birthdate: myAccountPerson.birthDate != null ? new Date(myAccountPerson.birthDate) : null,
                        language: myAccountPerson.language,
                        treatment: myAccountPerson.civility != null ? myAccountPerson.civility.value : null
                    };
                },
                showButton
            };
        }]);
