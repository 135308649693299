/*jslint node: true */
"use strict";
/*globals  angular */

/**
 * <d-bug var-bug="VARIABLE_REVISAR"></d-bug>
 */

angular
    .module('d_bug', [])
    .directive('dBug', dBugDir)
    .filter("prettyJSON", prettyJSON);

function prettyJSON() {
    return syntaxHighlight;
    function syntaxHighlight(json) {
        if (json) {
            json = JSON.stringify(json, null, 2);
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            });
        }
    }
}

function dBugDir() {
    var directive = {
        templateUrl: "templates/dBug.html",
        controller: ControllerDBug,
        link: link,
        restrict: 'E',
        scope: {
            varBug: '='
        }
    };
    return directive;

    function link(scope, element, attrs) {
        scope.varName = attrs.varBug;
    }
}

ControllerDBug.$inject = ['$scope'];

function ControllerDBug($scope) {
    $scope.$watch('varBug', function (newValue, oldValue) {
        if (newValue) {
            $scope.varType = typeof $scope.varBug;
        }
    });
}
