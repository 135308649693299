/*jslint node: true */
/*global angular , globalConfig */

"use strict";

angular
    .module('bank_transfer_data', [])
    .component('bankTransferData', {
        // templateUrl: 'templates/bank_transfer_data.html',
        templateUrl: function () {
            const template = 'templates/bank_transfer_data.html';
            return globalConfig.production ?
                `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                template;
        },
        bindings: {
            payment: '=',
            lang: '='
        }
    });
