/*jslint node: true */
/*global angular, document, masterUtils ,_ */

"use strict";

angular
    .module('payment_bugdet', ['budget_item_extended'])
    .directive('paymentBudget',
        ['gettextCatalog', '$state', 'Api', 'loadingService', 'myConfig', 'Bookings', '$timeout',
            function (gettextCatalog, $state, Api, loadingService, myConfig, Bookings, $timeout) {
                return {
                    restrict: 'E',
                    // templateUrl: 'templates/payment_budget.html',
                    templateUrl: function () {
                        return myConfig.production ?
                            `${myConfig.middlewareHost}/${myConfig.rootName}/templates/payment_budget.html` :
                            'templates/payment_budget.html';
                    },
                    scope: {
                        paymentMethods: '=methods',
                        selected: '=',
                        booking: '=',
                        parentForm: '=form',
                        additionalConcepts: '='
                    },
                    link: function ($scope) {

                        const DU = masterUtils.dateUtils;
                        const includedTypes = ['N', 'D', 'S', 'M', 'T', 'U'];

                        function calculate() {
                            $scope.calculatedPrices = {
                                contracted: null,
                                payed: null,
                                deposits: null,
                                pendingAmount: 0,
                                payNowAmount: 0,
                                totalPendingDeposits: 0,
                                downPaymentImportOptions: {},
                                vatIncluded: myConfig.vatIncluded
                            };

                            let additionalConcepts = {};
                            if ($scope.additionalConcepts) {
                                additionalConcepts = $scope.additionalConcepts;
                            }
                            // const budget = getPrice(additionalConcepts);

                            const baseNameDefault = gettextCatalog.getString('Price of stay');

                            const budget = Bookings.getPrice({
                                checkin: $scope.booking.checkin,
                                checkout: $scope.booking.checkin,
                                guestAges: $scope.booking.guestAges,
                                baseName: $scope.booking.productName || baseNameDefault,
                                showBasePriceDetail: $scope.booking.showBasePriceDetail,
                                vatIncluded: myConfig.vatIncluded,
                                basePrice: $scope.booking.bookingDetails,
                                sectionGroups: $scope.booking.sectionGroups,
                                additionalConcepts: $scope.booking.additionalConcepts,
                                gettextCatalog
                            });


                            $scope.calculatedPrices.downPaymentImportOptions = {};
                            _.each($scope.booking.checkingOptions, function (downpaymentOption) {
                                $scope.calculatedPrices.downPaymentImportOptions[downpaymentOption.idPaymentConditionRule] = downpaymentOption;
                            });
                            let downpaymentOption = null;
                            if ($scope.selected.idPaymentConditionRule != null) {
                                downpaymentOption = $scope.booking.checkingOptions.find(paymentMethod => {
                                    return paymentMethod.idPaymentConditionRule === $scope.selected.idPaymentConditionRule;
                                });
                            }
                            if (downpaymentOption == null && Array.isArray($scope.booking.checkingOptions) && $scope.booking.checkingOptions.length) {
                                downpaymentOption = $scope.booking.checkingOptions[0].idPaymentConditionRule;
                            }
                            if (downpaymentOption) {
                                budget.addPrice(downpaymentOption.price);
                            }

                            $scope.calculatedPrices.contracted = getPriceOfStay(budget);
                            $scope.calculatedPrices.payed = getPaymentsRealized(budget);
                            $scope.calculatedPrices.deposits = getDeposits(budget);

                            $scope.calculatedPrices.pendingAmount = $scope.calculatedPrices.contracted.import - $scope.calculatedPrices.payed.import;
                            $scope.calculatedPrices.payNowAmount = $scope.calculatedPrices.pendingAmount + $scope.calculatedPrices.totalPendingDeposits;
                            $scope.booking.totalAmount = $scope.calculatedPrices.payNowAmount;
                        }

                        $scope.calculate = calculate;

                        $scope.$on("calculate", function () {
                            $timeout(() => {
                                calculate();
                            });
                        });

                        function getPriceOfStay(budget) {
                            // const newBudget = new masterUtils.Price2(budget);
                            const newBudget = budget;
                            const contractedRender = newBudget.render('total', contractedFilter);

                            const totalLine = contractedRender.find(line => line.label === '@Total');
                            contractedRender.import = totalLine.import;
                            totalLine.label = gettextCatalog.getString('Total');

                            setLinesState(contractedRender);

                            function contractedFilter(l) {
                                if (l.typeUnitPrice && !includedTypes.includes(l.typeUnitPrice)) {
                                    return false;
                                }
                                return true;
                            }

                            return contractedRender;
                        }

                        function getPaymentsRealized(budget) {
                            // const newBudget = new masterUtils.Price2(budget);
                            const newBudget = budget;
                            newBudget.addPrice({
                                id: "payed",
                                class: "AGREGATOR",
                                label: gettextCatalog.getString('Payments realized'),

                                hideTotal: false,
                                expanded: false,
                                collapsable: true,

                                groupBy: ['NIGHT', 'DAY', 'WEEK', 'MONTH', 'TEMP', 'UNIT'],
                                execOrder: 11,

                                order: 6
                            });
                            newBudget.addPrice({
                                id: "downpayments",
                                class: "AGREGATOR",
                                label: gettextCatalog.getString('Downpayments'),

                                hideTotal: false,
                                expanded: false,
                                collapsable: true,

                                groupBy: ["GUARANTEE"],
                                execOrder: 10,

                                order: 5
                            });
                            const totalPayedRender = newBudget.render('total', totalPayedFilter);

                            const totalLine = totalPayedRender.find(line => line.label === '@Total');
                            totalPayedRender.import = totalLine.import;
                            totalLine.label = gettextCatalog.getString('Total');
                            setLinesState(totalPayedRender);

                            function totalPayedFilter(l) {
                                if (l.attributes && l.attributes.includes('DEPOSIT')) {
                                    return false;
                                }
                                if (l.attributes && !l.attributes.includes('PAYED')) {
                                    return false;
                                }
                                return true;
                            }

                            return totalPayedRender;
                        }

                        function getDeposits(budget, pending = false) {
                            $scope.calculatedPrices.totalPendingDeposits = 0;
                            // const newBudget = new masterUtils.Price2(budget);
                            const newBudget = budget;
                            newBudget.addPrice({
                                id: "deposits",
                                class: "AGREGATOR",
                                label: gettextCatalog.getString('Deposits'),

                                hideTotal: false,
                                expanded: false,
                                collapsable: true,

                                groupBy: ['DEPOSIT'],
                                execOrder: 12,

                                order: 7
                            });

                            newBudget.lines = newBudget.lines
                                .filter(line => {
                                    if (line.typeUnitPrice && (line.typeUnitPrice === 'X' || line.typeUnitPrice === 'Y')) {
                                        $scope.calculatedPrices.totalPendingDeposits += (line.price * line.quantity);
                                        return line.typeUnitPrice === 'X';
                                    }
                                    return false;
                                })
                                .map(line => {
                                    if (line.attributes && line.attributes.includes('PAYED')) {
                                        line.label = line.label + ` (${gettextCatalog.getString('PAYED')})`;
                                    }
                                    return line;
                                });

                            const depositRender = newBudget.render("total", masterUtils.Price2.attrFilter("DEPOSIT"));
                            const totalLine = depositRender.find(line => line.label === '@Total');
                            depositRender.import = totalLine.import;
                            totalLine.label = gettextCatalog.getString('Total Deposits');
                            // setLinesState(depositRender);
                            depositRender.forEach(line => line.show = true);
                            return depositRender;
                        }

                        function setLinesState(rendedLines) {
                            let aggregatorExpanded = [];
                            rendedLines.forEach(line => {
                                if (!Array.isArray(line.groupBy)) {
                                    line.groupBy = [line.groupBy];
                                }
                                if (line.class === 'AGREGATOR') {
                                    aggregatorExpanded = [];
                                    line.show = true;
                                    if (line.expanded) {
                                        aggregatorExpanded.push(...line.groupBy);
                                    }
                                }
                                if (line.attributes != null && aggregatorExpanded.some(aggregator => line.attributes.includes(aggregator))) {
                                    line.show = true;
                                }
                                line.show = true;
                            });
                        }

                    }

                };
            }]);
