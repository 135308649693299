"use strict";
/*jslint node:true, loopfunc:true, esversion:8 */
/*globals angular, _, document, clientConfig, SignaturePad, window, globalConfig, CustomEvent */

angular
    .module('initExpress', ['angularFileUpload'])
    .config(ResumeConfig)
    .controller('InitCtrl', InitCtrl)
    .directive("ngUploadChange", function () {
        return {
            scope: {
                ngUploadChange: "&"
            },
            link: function ($scope, $element, $attrs) {
                $element.on("change", function (event, key, value) {
                    $scope.ngUploadChange({$event: event, $key: key, $value: value});
                });
                $scope.$on("$destroy", function () {
                    $element.off();
                });
            }
        };
    })
    .filter('ageFilter', ageFilter);

InitCtrl.$inject = ['gettextCatalog', '$state', 'Api', 'loadingService', '$scope', 'myConfig', 'alertsService', 'Bookings', '$window', 'FileUploader', 'ngDialog', '$timeout', '$sce', 'AliceBiometrics'];
ResumeConfig.$inject = ['$stateProvider'];

function ResumeConfig($stateProvider) {
    $stateProvider.state('checkin', {
        url: '/checkin/:cryptedIdBooking?lang',
        views: {
            content: {
                // templateUrl: 'templates/init.html',
                templateUrl: function () {
                    return globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/init.html` :
                        'templates/init.html';
                },
                controller: 'InitCtrl',
                controllerAs: 'vm'
            }
        }
    });
}

function InitCtrl(gettextCatalog, $state, Api, loadingService, $scope, myConfig, alertsService, Bookings, $window, FileUploader, ngDialog, $timeout, $sce, AliceBiometrics) {

    const booking_additional_concepts_template = globalConfig.production ?
        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/booking_additional_concepts.html?version=${globalConfig.version}` :
        'templates/booking_additional_concepts.html';
    $scope.booking_additional_concepts_template = $sce.trustAsResourceUrl(booking_additional_concepts_template);

    const vm = this;
    const EMPTY_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjgAAADcCAQAAADXNhPAAAACIklEQVR42u3UIQEAAAzDsM+/6UsYG0okFDQHMBIJAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcCQADAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDkQAwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAegeayZAN3dLgwnAAAAAElFTkSuQmCC';

    vm.aliceBiometricsEnabled = false;
    vm.menu = [];
    vm.guests = {};
    vm.btnCheck = false;
    vm.section = {
        holder: false,
        guest: false,
        vehicles: false,
        keyCollection: false,
        printing: false,
        signing: false,
        selectPlot: false,
        payment: false
    };
    $scope.verifyRequireds = {};
    const linesByAdditionalConcepts = {};

    function loadData() {
        loadingService.enable('principal', gettextCatalog.getString("Loading"));
        vm.loading = true;

        const lang = $state.params.lang || myConfig.lang;

        Promise.all([
            Bookings.getInfo(),
            Api.getSections($state.params.cryptedIdBooking, null, null, lang)
        ])
            .then((response) => {

                vm.info = response[0];

                $scope.lang = response[1].lang;
                vm.lang = response[1].lang;
                vm.aliceBiometricsEnabled = response[1].aliceBiometricsEnabled ? response[1].aliceBiometricsEnabled : false;

                response[1].checkingOptions = Object.values(response[1].checkingOptions).sort((a, b) => a.order - b.order);

                vm.booking = response[1];

                $scope.checkInDate = vm.booking.checkin;

                if (window._loq) {
                    window._loq.push(["tag", vm.booking.idBooking]);
                }

                if (vm.booking.expressChecking != 'Si') {
                    alertsService.new('expressCheckin', 'error', 'AutoCheckin is disabled');
                    $state.go('login');
                    loadingService.disable('principal');
                    return;
                }

                $scope.booking = {
                    form: vm.booking.form,
                    payment: {},
                    rgpd: {},
                    additionalConcepts: {}
                };

                InitExpressCheckin();

            })
            .catch((err) => {
                if (typeof err === 'object') {
                    if (err.code && err.code == "expressCheckin.daysUntilCheckin" || err.code == "expressCheckin.isBlockedByReleaseHour") {
                        alertsService.new('expressCheckin', 'error', err.message || err.errorMsg);
                    } else {
                        alertsService.newError("Unexpected error in service", err.message || err.errorMsg);
                    }
                } else {
                    if (err != null &&
                        err.includes('You should do express checkin as minimum') ||
                        err.includes('You have exceeded the time limit') ||
                        err.includes('vatIncluded = false')
                    ) {
                        alertsService.new('expressCheckin', 'error', err);
                    } else if (err.indexOf("Negative balance") === 0) {
                        alertsService.new('expressCheckin', 'error', err);
                    } else {
                        alertsService.newError("Unexpected error in service", err.message || err.errorMsg);
                    }
                }
                loadingService.disable('principal');
                $state.go('login');
            });
    }

    function InitExpressCheckin() {

        loadingService.disable('autoCheckIn');
        if (vm.booking.checkinComplete.proformaExist) {
            loadingService.disable('principal');
            loadingService.enable('autoCheckIn', gettextCatalog.getString("Verifying payment"));
            setTimeout(() => {
                loadData();
            }, 1000 * 5);
            return;
        }

        if (vm.booking.checkinComplete.payment && vm.booking.checkinComplete.data) {
            showPdfIframe(vm.booking.echeckinTemplate);
            return;
        }

        const totalGuest = vm.booking.bookingData.adults + vm.booking.bookingData.childrens;
        const totalGuestOnBooking = vm.booking.data.guests.length;
        for (let j = totalGuest; j <= totalGuestOnBooking; j++) {
            vm.booking.data.guests.pop();
        }

        setFormData(vm.booking.form, vm.booking.data, vm.booking.bookingData, vm.booking.bookingDetails.lines);

        vm.maxGuests = (vm.booking.bookingData.adults + vm.booking.bookingData.childrens) - 1;

        while (Object.keys(vm.guests).length < vm.maxGuests) {
            newGuest();
        }

        if (!vm.booking.checkinComplete.payment || !vm.booking.checkinComplete.data) {
            vm.btnCheck = true;
        }

        $scope.booking.additionalConcepts = {};

        if (vm.booking.sectionGroups) {
            $scope.bookingForm = {sectionGroups: vm.booking.sectionGroups};
            _.each($scope.bookingForm.sectionGroups, function (sg) {
                _.each(sg.sections, function (s) {
                    _.each(s.additionalConcepts, function (ac) {
                        $scope.booking.additionalConcepts[ac.idAdditionalConcept] = ac.def;
                        if (ac.type === 'COMMENTS_BOX') return;
                        if (ac.type === 'SINGLE_CHOICE') {
                            for (const option of Object.values(ac.options)) {
                                if (linesByAdditionalConcepts[ac.idAdditionalConcept] == null) {
                                    linesByAdditionalConcepts[ac.idAdditionalConcept] = {};
                                }
                                linesByAdditionalConcepts[ac.idAdditionalConcept][option.idOption] = option.price.lines;
                            }
                        } else {
                            linesByAdditionalConcepts[ac.idAdditionalConcept] = ac.unitPrice.lines;
                        }
                    });
                });
            });
            $scope.$watch("booking.additionalConcepts", function () {
                vm.booking.additionalConcepts = $scope.booking.additionalConcepts;
                $scope.$broadcast('calculate');
                recalculateVehicleInputs();
            }, true);

        }

        loadRgpds(vm.booking.rgpdConsents, vm.booking.signEnabled);

        $scope.$watch("booking.payment", function () {
            $scope.$broadcast('calculate');
        }, true);

        loadingService.disable('principal');
        vm.loading = false;
        vm.showExpressCheckin = true;
        $scope.$apply();
        setClickOnInputDescriptions();
    }

    function loadRgpds(rgpdConsents = [], signEnabled = false) {
        $scope.activeRgpds = vm.info.rgpd.filter(rgpd => rgpd.apply_express_checkin);
        $scope.activeRgpds.forEach(rgpd => {
            if (rgpdConsents[rgpd.idConsent]) {
                $scope.booking.rgpd[rgpd.idConsent] = rgpdConsents[rgpd.idConsent].consented;
            }
        });
        vm.showSignature = signEnabled && $scope.activeRgpds.filter(rgpd => rgpd.required).length;
        if (vm.showSignature) {
            $scope.$watch('booking.signImage', function (newValue, oldValue) {
                if (oldValue != null) {
                    existSignImage();
                }
            });
        }
    }

    function existSignImage() {
        $scope.requiredSignError = false;
        if (!$scope.booking.signImage || $scope.booking.signImage === EMPTY_IMAGE) {
            alertsService.new("formError", "warning", gettextCatalog.getString("Sign is required, try clearing and signing again."), 5000);
            $scope.requiredSignError = true;
        }
        return $scope.requiredSignError;
    }

    function setFormData(form, formData, bookingData, baseLines) {
        if (form) {
            const tmpGuestAges = {};
            const keys = Object.keys(form.sections);
            for (let i = keys.length - 1; i >= 0; i--) {
                const section = form.sections[keys[i]];
                section.counter = 1;

                if (section.slug === "guest") {
                    section.counter = bookingData.adults + bookingData.childrens - 1;
                    _.each(formData.guests, function (guest, index) {
                        if (guest.use) {
                            vm.guests[guest.idAccompanyingPerson] = index;
                            _.each(section.inputs, function (input) {
                                if (input.name === 'birthdate' || input.name === 'expeditionDate' || input.name === 'expirationDate') {
                                    input.value[index] = formData.guests[index][input.name] ? new Date(formData.guests[index][input.name]) : '';

                                    if (input.name === 'birthdate' && input.value[index] instanceof Date) {
                                        tmpGuestAges[index] = {
                                            input: input,
                                            age: _calculateAge(input.value[index])
                                        };
                                    }
                                } else {
                                    input.value[index] = formData.guests[index][input.name];
                                }
                            });
                            refreshSize();
                        }
                    });
                }

                if (section.slug === "holder") {
                    _.each(section.inputs, function (input) {
                        if (input.name === 'birthdate' || input.name === 'expeditionDate' || input.name === 'expirationDate') {
                            input.value[0] = formData.client[input.name] ? new Date(formData.client[input.name]) : '';
                        } else {
                            input.value[0] = formData.client[input.name];
                        }
                    });
                }

                if (section.slug === "vehicles") {

                    const numVehicles = getIncludedVehiclesFromLines(baseLines);
                    section.counter = numVehicles;

                    _.each(formData.vehicles, function (vehicle) {
                        if (vehicle.use) {
                            _.each(section.inputs, function (input) {
                                input.value.push(vehicle[input.name]);
                            });
                        }
                    });
                }

            }

            _.each(tmpGuestAges, function (item, index) {
                $scope.reviewMinAgeRequiredValues(item.input, item.age, index);
            });

        }
    }

    function getIncludedVehiclesFromLines(lines = []) {
        return lines.reduce((result, line) => {
            if (line.attributes && line.attributes.includes('CONCEPT')) {
                line.quantity = (line.quantity == null || isNaN(line.quantity)) ? 1 : line.quantity;
                if (line.from == null) {
                    result = result + (line.quantity * line.includedVehicles);
                } else if (new Date(line.from).getTime() === new Date(vm.booking.checkin).getTime()
                ) {
                    result = result + (line.quantity * line.includedVehicles);
                }
            }
            return result;
        }, 0);
    }

    function recalculateVehicleInputs() {
        const baseIncludedVehicles = getIncludedVehiclesFromLines(vm.booking.bookingDetails.lines);
        const linesFromAdditionalConcepts = [];
        for (const entry of Object.entries($scope.booking.additionalConcepts)) {
            const additionalConceptKey = entry[0];
            const additionalConceptValue = entry[1];
            if (additionalConceptValue != null && additionalConceptValue != 0 && linesByAdditionalConcepts[additionalConceptKey] != null) {
                const linesForAdd = Array.isArray(linesByAdditionalConcepts[additionalConceptKey]) ?
                    linesByAdditionalConcepts[additionalConceptKey] :
                    linesByAdditionalConcepts[additionalConceptKey][additionalConceptValue];
                linesFromAdditionalConcepts.push(...linesForAdd);
            }
        }
        const includedVehiclesFromAdditionalConcepts = getIncludedVehiclesFromLines(linesFromAdditionalConcepts);
        vm.booking.form.sections.vehicles.counter = baseIncludedVehicles + includedVehiclesFromAdditionalConcepts;
    }

    const viewDocFrame = document.getElementById("viewDocFrame");
    let template = "";

    function showPdfIframe(echeckinTemplate) {
        $timeout(() => {
            template = echeckinTemplate;
            let docSrc = myConfig.host + '/api/docs/' + echeckinTemplate + '/' + $state.params.cryptedIdBooking + "?idProperty=" + myConfig.idProperty;
            if ($state.params.lang) {
                docSrc += "&lang=" + $state.params.lang;
            }
            viewDocFrame.src = docSrc;
            vm.showExpressCheckinComplete = true;
            loadingService.disable('principal');
            $scope.$apply();
        }, 0);
    }

    $scope.isDocumentComplete = function (idSection, index) {
        let flag = true;
        let section = $scope.booking.form.sections.holder;
        if (idSection !== 0) {
            section = $scope.booking.form.sections.guest;
        }
        _.each(section.inputs, function (input) {
            if (input.name === 'document') {
                if (input.value[index] === null || input.value[index] === undefined || input.value[index] === '') {
                    flag = false;
                }
            }
        });
        return flag;
    };

    $scope.reviewMinAgeRequiredValues = function (input, age, key) {
        let inputs = $scope.booking.form.sections.holder.inputs;
        let requiredFlag = "holder_0";
        if (input.idSection === 2) {
            inputs = $scope.booking.form.sections.guest.inputs;
            requiredFlag = "guest_" + key;
        }
        _.each(inputs, function (eachInput) {
            if (eachInput.schema && eachInput.schema.minAge) {
                if (age < eachInput.schema.minAge) {
                    $scope.verifyRequireds[requiredFlag] = age;
                } else {
                    delete $scope.verifyRequireds[requiredFlag];
                }
            }
        });
    };

    /**
     * UPLOADER SECTION
     */
    const uploaderOptions = {
        url: myConfig.host + "/api/upload_image/"
    };

    const uploader = new FileUploader(uploaderOptions);

    uploader.filters.push({
        name: 'imageFilter',
        fn: function (item /*{File|FileLikeObject}*/, options) {
            const type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        }
    });

    $scope.imagesQueue = {};
    $scope.uploadingImageReference = "";
    $scope.imagesUploaded = {};

    $scope.getFormItemValue = function (name, item) {
        let tmpItem;
        if (item.idSection === 0) {
            tmpItem = _.find(vm.booking.form.sections.holder.inputs, function (input) {
                return input.name === name;
            });
        } else {
            tmpItem = _.find(vm.booking.form.sections.guest.inputs, function (input) {
                return input.name === name;
            });
        }
        return tmpItem ? tmpItem.value[0] ? tmpItem.value[0] : null : null;
    };

    $scope.fileSelected = function ($event, $key, $value, value) {
        $scope.uploading = $value;
        let document = '';
        const key = ($key === 0) ? "holder" : "guest";
        _.each(vm.booking.form.sections, function (section) {
            if (section.slug === key) {
                _.each(section.inputs, function (input) {
                    if (input.name === 'document') {
                        document = input.value[$value];
                    }
                });
            }
        });
        const itemFile = $event.currentTarget.files[0];
        itemFile.alias = key + '_' + vm.booking.idBooking + '_' + document;
        $scope.uploadingImageReference = "input_" + $key + "_uploadImage_" + $value;
        uploader.addToQueue(itemFile);
    };

    $scope.uploading = null;

    $scope.checkUploading = function (value) {
        return (value === $scope.uploading);
    };

    $scope.setIdCardDirty = function (key, item) {
        const tmpItem = $scope.checkinForm ? $scope.checkinForm['input_' + item.idSection + '_document_' + key] : null;
        if (tmpItem) {
            tmpItem.$setDirty();
        }
    };

    $scope.uploader = uploader;

    $scope.images = [];

    $scope.getImageUrl = function (image) {
        const staticHostUrl = myConfig.production ? myConfig.middlewareHost : myConfig.host;
        return staticHostUrl + '/images/' + image;
    };

    /**
     * UPLOAD IMAGES
     */

    uploader.onAfterAddingFile = function (item) {
        item.file.name = item._file.alias;
        item.upload();
    };

    uploader.onWhenAddingFileFailed = function (item, filter, options) {
        $scope.uploading = null;
        $scope.openDialog("imageUploadPopUp");
    };

    uploader.onCompleteItem = function (fileItem, response, status) {
        $scope.uploading = null;
        if (status === 200) {
            $scope.images.push({idImage: response});
            $scope.imagesUploaded[$scope.uploadingImageReference] = fileItem._file.name;
            if ($scope.checkinForm[$scope.uploadingImageReference]) {
                $scope.checkinForm[$scope.uploadingImageReference].$setViewValue(fileItem);
                $scope.checkinForm[$scope.uploadingImageReference].$render();
            } else {
                // TODO Repair form for required images
                console.log('Repair form for required images');
            }
        } else {
            console.error("Error uploading", {fileItem, response, status});
        }
        $scope.uploadingImageReference = "";
    };

    function _calculateAge(birthday) {
        const ageDifMs = Date.now() - birthday.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    /**
     * END UPLOADER
     */

    $scope.getPrice = function (additionalConcepts) {
        return Bookings.getPrice({
            checkin: vm.booking.checkin,
            checkout: vm.booking.checkout,
            guestAges: vm.booking.guestAges,
            baseName: vm.booking.productName,
            showBasePriceDetail: vm.booking.showBasePriceDetail,
            vatIncluded: myConfig.vatIncluded,
            basePrice: vm.booking.bookingDetails,
            sectionGroups: $scope.bookingForm.sectionGroups,
            additionalConcepts,
            gettextCatalog
        });
    };

    vm.sendForm = sendForm;

    vm.inProcess = false;

    vm.countKeys = function (obj) {
        return Object.keys(obj).length;
    };

    function getCleanedBooking() {

        const checkInBody = {
            idBooking: vm.booking.idBooking,
            payment: _.cloneDeep($scope.booking.payment),
            totalAmount: vm.booking.totalAmount,
            lang: $scope.lang
        };

        if ($scope.booking.additionalConcepts && Object.keys($scope.booking.additionalConcepts).length) {
            checkInBody.additionalConcepts = angular.copy($scope.booking.additionalConcepts);
        }

        if ($scope.activeRgpds.length) {
            checkInBody.rgpd = {};
            $scope.activeRgpds.forEach((consent) => {
                checkInBody.rgpd[consent.idConsent] = $scope.booking.rgpd[consent.idConsent] || false;
            });
        }

        if (vm.showSignature) {
            checkInBody.signEnabled = true;
            checkInBody.signImage = $scope.booking.signImage;
        }

        if (!vm.booking.checkinComplete.data && $scope.booking.form) {
            checkInBody.data = {};
            _.each($scope.booking.form.sections, function (section) {
                _.each(section.inputs, function (input) {
                    if (input.value.length > 0) {
                        if (!checkInBody.data[section.slug]) {
                            checkInBody.data[section.slug] = ((section.slug === 'holder') ? {} : []);
                        }
                        _.each(input.value, function (value, index) {
                            if (input.type === "uploadImage") return;
                            if (section.slug === 'holder') {
                                checkInBody.data[section.slug][input.name] = value;
                            } else if (section.slug === 'guest') {
                                if (!checkInBody.data[section.slug][index]) checkInBody.data[section.slug][index] = {};
                                checkInBody.data[section.slug][index][input.name] = value;
                                if (!checkInBody.data[section.slug][index].idAccompanyingPerson) {
                                    const guestSelected = _.findKey(vm.guests, function (v, k) {
                                        return v === index;
                                    });
                                    if (guestSelected !== null) {
                                        checkInBody.data[section.slug][index].idAccompanyingPerson = guestSelected;
                                    }
                                }
                            } else {
                                if (!checkInBody.data[section.slug][index]) checkInBody.data[section.slug][index] = {};
                                checkInBody.data[section.slug][index][input.name] = value;
                            }
                        });
                    }
                });
            });
        }

        return checkInBody;
    }

    function sendForm() {

        alertsService.reset();

        if ($scope.checkinForm && ($scope.booking.form || !vm.booking.checkinComplete.checkin)) {

            $scope.checkinForm.$setSubmitted(true);
            if (!$scope.checkinForm.$valid) {
                if (Object.keys(vm.guests).length === 0 && vm.guests > 0) {
                    alertsService.new("formError", "warning", gettextCatalog.getString("You need to introduce the guests."), 5000);
                }
                alertsService.new("formError", "warning", gettextCatalog.getString("Verify form inputs."), 5000);
                $window.scrollTo(0, 0);
                return;
            }

            if (vm.showSignature && existSignImage()) {
                // alertsService.new("signatureFormError", "warning", gettextCatalog.getString("Sign is required."), 5000);
                return;
            }

        }

        const cleanedBooking = getCleanedBooking();

        // Validate duplicate guest document
        let sameDocument = null;
        if (cleanedBooking.data &&
            cleanedBooking.data.guest) {
            for (let gIndex = 0; gIndex < cleanedBooking.data.guest.length; gIndex++) {
                const actualGuest = cleanedBooking.data.guest[gIndex];
                if (actualGuest.document == null || actualGuest.document === '') {
                    continue;
                }
                sameDocument = cleanedBooking.data.guest.find((eGuest, index) => {
                    return index > gIndex && actualGuest.document === eGuest.document;
                });
                if (sameDocument != null) {
                    break;
                }
            }
        }
        if (sameDocument != null) {
            alertsService.new("signatureFormError", "warning", gettextCatalog.getString("Guests with duplicate documents are not permitted."), 5000);
            return;
        }

        if (vm.inProcess) return;
        vm.inProcess = true;
        loadingService.enable('principal', gettextCatalog.getString("In process checkin"), true);
        if (window._loq) {
            window._loq.push(["tag", "Submit form"]);
        }

        Api.payBooking(cleanedBooking).then(function (res) {
            alertsService.new("formError", "success", gettextCatalog.getString("Booking information saved!"), 3000);
            loadingService.disable('principal');
            vm.inProcess = false;
            vm.booking.checkinComplete = res.checkinComplete;

            if (window._loq) {
                window._loq.push(["tag", "Success"]);
            }

            if (res.tpvPaymentData != null && res.tpvPaymentData.url != null) {
                document.location.href = res.tpvPaymentData.url;
                return;
            }

            if (res.tpvPaymentData) {
                // Construccion del form al tpv
                const formTpv = document.createElement("form");
                formTpv.setAttribute('method', "post");
                // formTpv.setAttribute('target',"_blank");
                formTpv.setAttribute('action', res.tpvPaymentData.uri);

                const inpMarchantParameters = document.createElement("input");
                inpMarchantParameters.setAttribute('type', "hidden");
                inpMarchantParameters.setAttribute('name', "Ds_MerchantParameters");
                inpMarchantParameters.setAttribute('value', res.tpvPaymentData.Ds_MerchantParameters);

                const inpSignature = document.createElement("input");
                inpSignature.setAttribute('type', "hidden");
                inpSignature.setAttribute('name', "Ds_Signature");
                inpSignature.setAttribute('value', res.tpvPaymentData.Ds_Signature);

                const inpSignatureVersion = document.createElement("input");
                inpSignatureVersion.setAttribute('type', "hidden");
                inpSignatureVersion.setAttribute('name', "Ds_SignatureVersion");
                inpSignatureVersion.setAttribute('value', res.tpvPaymentData.Ds_SignatureVersion);

                formTpv.appendChild(inpMarchantParameters);
                formTpv.appendChild(inpSignature);
                formTpv.appendChild(inpSignatureVersion);
                document.getElementById('hiddenTPV').appendChild(formTpv);
                formTpv.submit();
            }

            if (res.checkinComplete.payment && res.checkinComplete.data) {
                // alertsService.new("formError", "warning", gettextCatalog.getString("Loading express checkin document."), 3000);
                // $timeout(function(){
                vm.showExpressCheckin = false;
                showPdfIframe(res.echeckinTemplate);
                // }, 3000);
            }
        }, processError);

    }

    function processError(err) {

        console.error(err);

        vm.inProcess = false;

        const errorCode = err.code || err.errorCode;
        const errorMessage = err.message || err.errorMessage || '';

        if (window._loq) {
            window._loq.push(["tag", "Error"]);
            if (errorCode) {
                window._loq.push(["tag", errorCode]);
            } else {
                window._loq.push(["tag", "UndefinedError"]);
            }
        }

        loadingService.disable('principal');

        if (errorCode) {
            if (errorCode == "bank.paymentError") {
                $scope.cardDenied = true;
                if (errorMessage.includes("DENEGADA") || errorMessage.includes("DENEGADA")) {
                    alertsService.new(errorCode, 'error', gettextCatalog.getString("The card has been denied."));
                } else {
                    alertsService.newError(errorCode, "Unexpected error in payment", errorMessage, true);
                }
            } else if (errorCode === 'bookings.validate') {
                alertsService.new(errorCode, "warning", err.message);
            } else if (errorCode === 'Undefined payment method') {
                $window.location.reload();
            } else if (errorCode === 'form.validation') {
                Object.keys(err.message)
                    .forEach((entityKey) => {
                        Object.keys(err.message[entityKey])
                            .forEach((attribute) => {
                                alertsService.new(errorCode, "warning", `Section: ${entityKey}, missing ${attribute}`);
                            });
                    });
            } else {
                alertsService.newError("Unexpected error in service", errorMessage, true);
            }
            return;
        }

        alertsService.newError("Unexpected error in service", errorMessage, true);

    }

    /**
     * copy 'addressKeys' inputs to companion from holder
     */
    vm.addressKeys = ['address', 'city', 'zip', 'country'];

    vm.carSelected = function (car) {
        if (car.use) {
            _.each(vm.booking.data.vehicles, function (ve) {
                ve.use = false;
            });
            car.use = true;
            vm.booking.form.sections.vehicles.inputs[0].value = [car.carPlate];
            vm.booking.form.sections.vehicles.inputs[1].value = [car.model];
            vm.booking.form.sections.vehicles.inputs[2].value = [car.color];
        } else {
            vm.booking.form.sections.vehicles.inputs[0].value = [];
            vm.booking.form.sections.vehicles.inputs[1].value = [];
            vm.booking.form.sections.vehicles.inputs[2].value = [];
        }
    };

    vm.toggleCar = function (flag) {
        _.each(vm.booking.data.vehicles, function (ve) {
            ve.use = false;
        });
        if (flag) {
            vm.booking.form.sections.vehicles.inputs[0].value = [];
            vm.booking.form.sections.vehicles.inputs[1].value = [];
            vm.booking.form.sections.vehicles.inputs[2].value = [];
        }
        vm.newCar = flag;
    };

    let lastNewGuest = 0;
    vm.guestSize = 0;

    function getIndexHole() {
        let index = 0;
        for (let i = 0; i < Object.keys(vm.guests).length; i++) {
            const finded = _.find(vm.guests, function (v) {
                return i === v;
            });
            if (finded === null) {
                break;
            }
            index++;
        }
        return index;
    }

    function newGuest() {
        vm.guests["NEW" + lastNewGuest] = getIndexHole();
        lastNewGuest++;
        refreshSize();
    }

    function refreshSize() {
        vm.guestSize = Object.keys(vm.guests).length;
    }

    vm.getGuestClass = function (index) {
        if (vm.guestHasErrors(vm.guests[index])) {
            return "redBackground";
        } else {
            return "greenBackground";
        }
    };

    vm.missingFormData = function () {
        if ($scope.checkinForm && $scope.checkinForm.$submitted) {
            var formError = _.find($scope.checkinForm, function (val, key) {
                if (key.indexOf('input_2_') === 0 && val.$invalid) {
                    return true;
                }
            });
            return formError ? true : false;
        } else {
            return false;
        }
    };

    vm.guestHasErrors = function (index) {
        if ($scope.checkinForm == null) {
            return false;
        }
        var formError = _.find($scope.checkinForm, function (val, key) {
            if (key.indexOf('input_2_') === 0 && parseInt(key.charAt(key.length - 1)) === index && val.$invalid) {
                return true;
            }
        });
        return formError ? true : false;
    };

    vm.guestSelected = function (gu) {
        if (gu.use) {
            var index = getIndexHole();
            vm.guests[gu.idAccompanyingPerson] = index;
            _.each(vm.booking.form.sections.guest.inputs, function (input) {
                if (input.name === 'birthdate' || input.name === 'expeditionDate') {
                    gu[input.name] = gu[input.name] ? new Date(gu[input.name]) : null;
                } else {
                    input.value[index] = gu[input.name];
                }
            });
            gu.index = index;
        } else {
            _.each(vm.booking.form.sections.guest.inputs, function (input) {
                input.value[vm.guests[gu.idAccompanyingPerson]] = "";
            });
            delete vm.guests[gu.idAccompanyingPerson];
        }
        refreshSize();
    };

    vm.getInputValue = function (section, key, index) {
        const tmpInput = _.find(vm.booking.form.sections[section].inputs, function (v) {
            if (v.name === key) return true;
        });
        if (!tmpInput) {
            return '';
        } else {
            return tmpInput.value[index || 0];
        }
    };

    vm.showCopyAddress = function () {
        let flag = false;
        $scope.booking.form.sections.guest.inputs.forEach(function (input) {
            if (vm.addressKeys.indexOf(input.name) !== -1) flag = true;
        });
        return flag;
    };

    vm.copyAddress = function (i) {
        const holderNames = {};
        $scope.booking.form.sections.holder.inputs.forEach(function (input) {
            if (input.value[0] && vm.addressKeys.indexOf(input.name) !== -1) holderNames[input.name] = angular.copy(input.value[0]);
        });
        $scope.booking.form.sections.guest.inputs.forEach(function (input) {
            if (holderNames[input.name]) input.value[i] = angular.copy(holderNames[input.name]);
        });
    };

    vm.initAliceBiometricsOnboarding_holder = async function () {
        await AliceBiometrics.initAliceBiometricsOnBoarding_Holder($state.params.cryptedIdBooking);
        const isSuccess = await AliceBiometrics.getPromise();
        if (isSuccess) {
            loadingService.enable('principal', gettextCatalog.getString("Loading"));
            const dataFromAliceBiometrics = await AliceBiometrics.getDataFromAliceBiometrics_holder($state.params.cryptedIdBooking);
            mergeHolderData(dataFromAliceBiometrics);
            loadingService.disable('principal');
        }

    };

    function getExistingAliceBiometricsUuids() {
        const result = new Set();
        const aliceBiometricsUuidsInput = vm.booking.form.sections.guest.inputs.find((i) => i.type === 'aliceBiometricsUuid');
        const uuidsInInput = aliceBiometricsUuidsInput == null ? [] : aliceBiometricsUuidsInput.value.filter((v) => v != null);
        uuidsInInput.forEach((uuid) => result.add(uuid));
        const uuidsInData = vm.booking.data.guests.map((guest) => guest.aliceBiometricsUuid).filter((v) => v != null);
        uuidsInData.forEach((uuid) => result.add(uuid));
        return [...result];
    }

    vm.initAliceBiometricsOnboarding_companion = async function (guestIndex, isNew) {

        const existingData = vm.booking.data.guests[guestIndex] || {};
        let inputExisting = vm.booking.form.sections.guest.inputs.find((i) => i.type == 'aliceBiometricsUuid');

        const numGuest = vm.booking.form.sections.guest.counter;

        let existingAliceBiometricsUuid = existingData.aliceBiometricsUuid;
        if (inputExisting != null && inputExisting.value.length == 0) {
            inputExisting.value = Array.from({length: numGuest}, () => null);
        }
        if (inputExisting != null && existingAliceBiometricsUuid == null) {
            existingAliceBiometricsUuid = inputExisting.value[guestIndex];
        }
        const existingAliceBiometricsUuidInOtherCompanions = getExistingAliceBiometricsUuids();
        await AliceBiometrics.initAliceBiometricsOnBoarding_Companion($state.params.cryptedIdBooking, isNew, existingData.companionId, existingData.document, existingAliceBiometricsUuid, existingAliceBiometricsUuidInOtherCompanions);
        const {isSuccess, aliceBiometricsUuid} = await AliceBiometrics.getPromise();


        if (inputExisting == null) {
            inputExisting = {
                type: 'aliceBiometricsUuid',
                value: Array.from({length: numGuest}, () => null),
                idSection: 2

            };
            vm.booking.form.sections.guest.inputs.push(inputExisting);
        }

        inputExisting.value[guestIndex] = aliceBiometricsUuid;


        if (isSuccess) {
            loadingService.enable('principal', gettextCatalog.getString("Loading"));
            const dataFromAliceBiometrics = await AliceBiometrics.getDataFromAliceBiometrics_companion($state.params.cryptedIdBooking, aliceBiometricsUuid);
            if (dataFromAliceBiometrics != null) {
                mergeCompanionData(dataFromAliceBiometrics, guestIndex, aliceBiometricsUuid);
            }
            loadingService.disable('principal');
        }
    };

    function mergeHolderData(dataToMerge) {
        // const sectionInputs = vm.booking.form?.sections?.holder?.inputs;
        const sectionInputs = vm.booking.form ? vm.booking.form.sections ? vm.booking.form.sections.holder ? vm.booking.form.sections.holder.inputs : null : null : null;
        if (sectionInputs == null) {
            alertsService.newError('Error', 'Holder section inputs is Null');
        }
        // const existingData = vm.booking.data?.client;
        const existingData = vm.booking.data ? vm.booking.data.client : null;
        if (existingData == null) {
            alertsService.newError('Error', 'Holder section inputs is Null');
        }
        _.each(sectionInputs, function (input) {
            const inputName = input.name;
            if (dataToMerge[inputName] != null) {
                input.value[0] = (input.name === 'birthdate' || input.name === 'expeditionDate' || input.name === 'expirationDate') ?
                    new Date(dataToMerge[inputName]) : dataToMerge[inputName];
                const inputKey = `${input.idSection}_${input.name}_0`;
                const event = new CustomEvent('checkDate_' + inputKey);
                window.dispatchEvent(event);

            }
        });
    }

    function mergeCompanionData(dataToMerge, guestIndex) {

        try {

            /*
         TODO COMENTAR CON POL
         //first create map idAccompanyingPerson=>position in array
        // const guestData = vm.booking.data?.guests || [];
        const guestData = vm.booking.data ? vm.booking.data.guests ? vm.booking.data.guests : [] : [];
        const guestPositionMap = new Map();
        guestData.forEach((guest, index) => {
            guestPositionMap.set(guest.idAccompanyingPerson, index);
        });


        //if companionId is not in the map, return
        if (!guestPositionMap.has(companionId)) {
            alertsService.newError('Error', 'Companion id not found in guest data');
            return;
        }

        // const sectionInputs = vm.booking.form?.sections?.guest?.inputs;
        const sectionInputs = vm.booking.form ? vm.booking.form.sections ? vm.booking.form.sections.guest ? vm.booking.form.sections.guest.inputs : null : null : null;
        if (sectionInputs == null) {
            alertsService.newError('Error', 'Guest section inputs is Null');
        }

             */


            const sectionInputs = vm.booking.form.sections.guest.inputs;
            if (sectionInputs == null) {
                alertsService.newError('Error', 'Guest section inputs is Null');
            }

            const guestData = vm.booking.data.guests || [];
            const existingData = guestData[guestIndex];


            _.each(sectionInputs, function (input) {
                const inputName = input.name;
                if (dataToMerge[inputName] != null) {
                    input.value[guestIndex] = (input.name === 'birthdate' || input.name === 'expeditionDate' || input.name === 'expirationDate') ?
                        new Date(dataToMerge[inputName]) : dataToMerge[inputName];
                    const inputKey = `${input.idSection}_${input.name}_${guestIndex}`;
                    const event = new CustomEvent('checkDate_' + inputKey);
                    window.dispatchEvent(event);
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    $scope.printBooking = function () {
        var contentWindow = viewDocFrame.contentWindow;
        contentWindow.print();
    };

    $scope.openPDF = function () {
        if (template) {
            let docSrc = myConfig.host + "/api/docs/" + template + "/" + $state.params.cryptedIdBooking + "?mode=pdf&idProperty=" + myConfig.idProperty;
            if ($state.params.lang) {
                docSrc += "&lang=" + $state.params.lang;
            }
            $window.open(
                docSrc,
                "name='Print version'",
                'left=0, top=0, width=750, height=900, toolbar=0, resizable=0'
            );
        } else {
            alertsService.new('bookingTemplate', 'error', gettextCatalog.getString("This product does haven't a template"));
        }
    };

    $scope.openDialog = function (type) {
        ngDialog.open({
            template: type,
            className: 'ngdialog-theme-default html_content largeDialog'
        });
    };

    function anyInvalidConsent() {
        return vm.info.rgpd
            .filter(rgpd => rgpd.apply_express_checkin)
            .some(vm.isConsentInvalid);
    }

    vm.isConsentInvalid = function (consent) {
        return consent.required && $scope.checkinForm &&
            $scope.checkinForm['rgpd_' + consent.idConsent] &&
            $scope.checkinForm['rgpd_' + consent.idConsent].$invalid &&
            ($scope.checkinForm.$submitted || !$scope.checkinForm['rgpd_' + consent.idConsent].$pristine);
    };

    /* ADDITIONAL CONCEPTS */

    $scope.isSGVisible = function (sectionGroup) {
        return !!_.find(sectionGroup.sections, function (section) {
            return !!_.find(section.additionalConcepts, function (additionalConcept) {
                return $scope.isACVisible(additionalConcept);
            });
        });
    };

    /*jslint evil: true */
    $scope.isACVisible = function (additionalConcept) {

        if (!additionalConcept.isVisible) return true;
        var v;
        var S = "\"use strict\"; \n";
        S += "var booking = " + JSON.stringify($scope.booking) + ";\n";
        S += "var value =" + JSON.stringify($scope.booking.additionalConcepts[additionalConcept.idAdditionalConcept]) + ";\n";
        S += "var totalBudget =" + JSON.stringify($scope.calculatedPrices.totalImport) + ";\n";
        S += "var totalPayment =" + JSON.stringify($scope.calculatedPrices.paymentImport) + ";\n";
        S += "var totalRemaining =" + JSON.stringify($scope.calculatedPrices.totalRemaining) + ";\n";
        S += "var params =" + JSON.stringify($state.params) + ";\n";
        S += "var lang ='" + $scope.lang + "';\n";

        S += additionalConcept.isVisible;

        try {
            v = eval(S);
        } catch (err) {
            v = false;
        }

        var visible = (v === false) ? false : true;

        if (!visible) {
            $scope.booking.additionalConcepts[additionalConcept.idAdditionalConcept] = additionalConcept.def;
        }

        return visible;
    };

    $scope.getACInvalidMsg = function (additionalConcept) {

        if (!additionalConcept.validate) return null;

        var errStr = null;
        var S = "\"use strict\"; \n";
        S += "var booking = " + JSON.stringify($scope.booking) + ";\n";
        S += "var value =" + JSON.stringify($scope.booking.additionalConcepts[additionalConcept.idAdditionalConcept]) + ";\n";
        S += "var totalBudget =" + JSON.stringify($scope.calculatedPrices.totalImport) + ";\n";
        S += "var totalPayment =" + JSON.stringify($scope.calculatedPrices.paymentImport) + ";\n";
        S += "var totalRemaining =" + JSON.stringify($scope.calculatedPrices.totalRemaining) + ";\n";
        S += "var params =" + JSON.stringify($state.params) + ";\n";
        S += "var lang ='" + $scope.lang + "';\n";

        S += additionalConcept.validate;

        try {
            eval(S);
        } catch (err) {
            if (err instanceof Error) {
                errStr = err.message;
            } else {
                errStr = err.toString();
            }
        }

        if (errStr) {
            if (errStr.toUpperCase == "REQUIRED") errStr = gettextCatalog.getString("Required");
            if (errStr.toUpperCase == "INVALID") errStr = gettextCatalog.getString("Invalid");
        }
        return errStr;
    };

    /* END ADDITIONAL CONCEPTS */

    loadData();

    function setClickOnInputDescriptions() {
        setTimeout(function () {
            angular.element('.showInputDescription').bind("click", function (event) {
                const scope = angular.element(event.currentTarget.parentElement).scope();

                let description = '';
                if (scope.ngAdditionalConcept) description = scope.ngAdditionalConcept.description;
                if (scope.option) description = scope.option.description;
                if (scope.sectionGroup) description = scope.sectionGroup.description;
                if (scope.section) description = scope.section.description;

                ngDialog.open({
                    template: 'moreInfoPopupTemplate',
                    className: 'ngdialog-theme-default html_content largeDialog',
                    controller: ["$scope", function ($childScope) {
                        $childScope.description = description;
                    }]
                });
            });

        }, 1000);
    }

}

function ageFilter() {
    function calculateAge(birthday) { // birthday is a date
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    function monthDiff(d1, d2) {
        if (d1 < d2) {
            var months = d2.getMonth() - d1.getMonth();
            return months <= 0 ? 0 : months;
        }
        return 0;
    }

    return function (birthdate) {
        if (birthdate instanceof Date) {

        } else {
            birthdate = new Date(birthdate);
        }
        const age = calculateAge(birthdate);
        if (age === 0) {
            return monthDiff(birthdate, new Date()) + ' months';
        }
        return age + ' years';
    };
}
