/*jslint node: true */
"use strict";
/*globals angular */

angular.module('select_country', ['gettext'])
    .directive('selectCountry', ['Bookings','gettextCatalog', function (Bookings,gettextCatalog) {
        return {
            template: '<select ng-model="selectedValue" ng-options="c.value as c.name for c in countries" style="width:100%"></select>',
            restrict: 'E',
            require: '^ngModel',
            scope: {
                selectedValue: '=ngModel'
            },
            link: function (scope, elem, attrs) {

                let countries = [

                    {
                        "name": gettextCatalog.getString("Spain"),
                        "value": "ES"
                    },
                    {
                        "name": gettextCatalog.getString("Afghanistan"),
                        "value": "AF"
                    },
                    {
                        "name": gettextCatalog.getString("Åland Islands"),
                        "value": "AX"
                    },
                    {
                        "name": gettextCatalog.getString("Albania"),
                        "value": "AL"
                    },
                    {
                        "name": gettextCatalog.getString("Algeria"),
                        "value": "DZ"
                    },
                    {
                        "name": gettextCatalog.getString("American Samoa"),
                        "value": "AS"
                    },
                    {
                        "name": gettextCatalog.getString("Andorra"),
                        "value": "AD"
                    },
                    {
                        "name": gettextCatalog.getString("Angola"),
                        "value": "AO"
                    },
                    {
                        "name": gettextCatalog.getString("Anguilla"),
                        "value": "AI"
                    },
                    {
                        "name": gettextCatalog.getString("Antarctica"),
                        "value": "AQ"
                    },
                    {
                        "name": gettextCatalog.getString("Antigua and Barbuda"),
                        "value": "AG"
                    },
                    {
                        "name": gettextCatalog.getString("Argentina"),
                        "value": "AR"
                    },
                    {
                        "name": gettextCatalog.getString("Armenia"),
                        "value": "AM"
                    },
                    {
                        "name": gettextCatalog.getString("Aruba"),
                        "value": "AW"
                    },
                    {
                        "name": gettextCatalog.getString("Australia"),
                        "value": "AU"
                    },
                    {
                        "name": gettextCatalog.getString("Austria"),
                        "value": "AT"
                    },
                    {
                        "name": gettextCatalog.getString("Azerbaijan"),
                        "value": "AZ"
                    },
                    {
                        "name": gettextCatalog.getString("Bahamas"),
                        "value": "BS"
                    },
                    {
                        "name": gettextCatalog.getString("Bahrain"),
                        "value": "BH"
                    },
                    {
                        "name": gettextCatalog.getString("Bangladesh"),
                        "value": "BD"
                    },
                    {
                        "name": gettextCatalog.getString("Barbados"),
                        "value": "BB"
                    },
                    {
                        "name": gettextCatalog.getString("Belarus"),
                        "value": "BY"
                    },
                    {
                        "name": gettextCatalog.getString("Belgium"),
                        "value": "BE"
                    },
                    {
                        "name": gettextCatalog.getString("Belize"),
                        "value": "BZ"
                    },
                    {
                        "name": gettextCatalog.getString("Benin"),
                        "value": "BJ"
                    },
                    {
                        "name": gettextCatalog.getString("Bermuda"),
                        "value": "BM"
                    },
                    {
                        "name": gettextCatalog.getString("Bhutan"),
                        "value": "BT"
                    },
                    {
                        "name": gettextCatalog.getString("Bolivia, Plurinational State of"),
                        "value": "BO"
                    },
                    {
                        "name": gettextCatalog.getString("Bonaire, Sint Eustatius and Saba"),
                        "value": "BQ"
                    },
                    {
                        "name": gettextCatalog.getString("Bosnia and Herzegovina"),
                        "value": "BA"
                    },
                    {
                        "name": gettextCatalog.getString("Botswana"),
                        "value": "BW"
                    },
                    {
                        "name": gettextCatalog.getString("Bouvet Island"),
                        "value": "BV"
                    },
                    {
                        "name": gettextCatalog.getString("Brazil"),
                        "value": "BR"
                    },
                    {
                        "name": gettextCatalog.getString("British Indian Ocean Territory"),
                        "value": "IO"
                    },
                    {
                        "name": gettextCatalog.getString("Brunei Darussalam"),
                        "value": "BN"
                    },
                    {
                        "name": gettextCatalog.getString("Bulgaria"),
                        "value": "BG"
                    },
                    {
                        "name": gettextCatalog.getString("Burkina Faso"),
                        "value": "BF"
                    },
                    {
                        "name": gettextCatalog.getString("Burundi"),
                        "value": "BI"
                    },
                    {
                        "name": gettextCatalog.getString("Cambodia"),
                        "value": "KH"
                    },
                    {
                        "name": gettextCatalog.getString("Cameroon"),
                        "value": "CM"
                    },
                    {
                        "name": gettextCatalog.getString("Canada"),
                        "value": "CA"
                    },
                    {
                        "name": gettextCatalog.getString("Cape Verde"),
                        "value": "CV"
                    },
                    {
                        "name": gettextCatalog.getString("Cayman Islands"),
                        "value": "KY"
                    },
                    {
                        "name": gettextCatalog.getString("Central African Republic"),
                        "value": "CF"
                    },
                    {
                        "name": gettextCatalog.getString("Chad"),
                        "value": "TD"
                    },
                    {
                        "name": gettextCatalog.getString("Chile"),
                        "value": "CL"
                    },
                    {
                        "name": gettextCatalog.getString("China"),
                        "value": "CN"
                    },
                    {
                        "name": gettextCatalog.getString("Christmas Island"),
                        "value": "CX"
                    },
                    {
                        "name": gettextCatalog.getString("Cocos (Keeling) Islands"),
                        "value": "CC"
                    },
                    {
                        "name": gettextCatalog.getString("Colombia"),
                        "value": "CO"
                    },
                    {
                        "name": gettextCatalog.getString("Comoros"),
                        "value": "KM"
                    },
                    {
                        "name": gettextCatalog.getString("Congo"),
                        "value": "CG"
                    },
                    {
                        "name": gettextCatalog.getString("Congo, the Democratic Republic of the"),
                        "value": "CD"
                    },
                    {
                        "name": gettextCatalog.getString("Cook Islands"),
                        "value": "CK"
                    },
                    {
                        "name": gettextCatalog.getString("Costa Rica"),
                        "value": "CR"
                    },
                    {
                        "name": gettextCatalog.getString("Côte d'Ivoire"),
                        "value": "CI"
                    },
                    {
                        "name": gettextCatalog.getString("Croatia"),
                        "value": "HR"
                    },
                    {
                        "name": gettextCatalog.getString("Cuba"),
                        "value": "CU"
                    },
                    {
                        "name": gettextCatalog.getString("Curaçao"),
                        "value": "CW"
                    },
                    {
                        "name": gettextCatalog.getString("Cyprus"),
                        "value": "CY"
                    },
                    {
                        "name": gettextCatalog.getString("Czech Republic"),
                        "value": "CZ"
                    },
                    {
                        "name": gettextCatalog.getString("Denmark"),
                        "value": "DK"
                    },
                    {
                        "name": gettextCatalog.getString("Djibouti"),
                        "value": "DJ"
                    },
                    {
                        "name": gettextCatalog.getString("Dominica"),
                        "value": "DM"
                    },
                    {
                        "name": gettextCatalog.getString("Dominican Republic"),
                        "value": "DO"
                    },
                    {
                        "name": gettextCatalog.getString("Ecuador"),
                        "value": "EC"
                    },
                    {
                        "name": gettextCatalog.getString("Egypt"),
                        "value": "EG"
                    },
                    {
                        "name": gettextCatalog.getString("El Salvador"),
                        "value": "SV"
                    },
                    {
                        "name": gettextCatalog.getString("Equatorial Guinea"),
                        "value": "GQ"
                    },
                    {
                        "name": gettextCatalog.getString("Eritrea"),
                        "value": "ER"
                    },
                    {
                        "name": gettextCatalog.getString("Spain"),
                        "value": "ES"
                    },
                    {
                        "name": gettextCatalog.getString("Estonia"),
                        "value": "EE"
                    },
                    {
                        "name": gettextCatalog.getString("Ethiopia"),
                        "value": "ET"
                    },
                    {
                        "name": gettextCatalog.getString("Falkland Islands (Malvinas)"),
                        "value": "FK"
                    },
                    {
                        "name": gettextCatalog.getString("Faroe Islands"),
                        "value": "FO"
                    },
                    {
                        "name": gettextCatalog.getString("Fiji"),
                        "value": "FJ"
                    },
                    {
                        "name": gettextCatalog.getString("Finland"),
                        "value": "FI"
                    },
                    {
                        "name": gettextCatalog.getString("France"),
                        "value": "FR"
                    },
                    {
                        "name": gettextCatalog.getString("French Guiana"),
                        "value": "GF"
                    },
                    {
                        "name": gettextCatalog.getString("French Polynesia"),
                        "value": "PF"
                    },
                    {
                        "name": gettextCatalog.getString("French Southern Territories"),
                        "value": "TF"
                    },
                    {
                        "name": gettextCatalog.getString("Gabon"),
                        "value": "GA"
                    },
                    {
                        "name": gettextCatalog.getString("Gambia"),
                        "value": "GM"
                    },
                    {
                        "name": gettextCatalog.getString("Georgia"),
                        "value": "GE"
                    },
                    {
                        "name": gettextCatalog.getString("Germany"),
                        "value": "DE"
                    },
                    {
                        "name": gettextCatalog.getString("Ghana"),
                        "value": "GH"
                    },
                    {
                        "name": gettextCatalog.getString("Gibraltar"),
                        "value": "GI"
                    },
                    {
                        "name": gettextCatalog.getString("Greece"),
                        "value": "GR"
                    },
                    {
                        "name": gettextCatalog.getString("Greenland"),
                        "value": "GL"
                    },
                    {
                        "name": gettextCatalog.getString("Grenada"),
                        "value": "GD"
                    },
                    {
                        "name": gettextCatalog.getString("Guadeloupe"),
                        "value": "GP"
                    },
                    {
                        "name": gettextCatalog.getString("Guam"),
                        "value": "GU"
                    },
                    {
                        "name": gettextCatalog.getString("Guatemala"),
                        "value": "GT"
                    },
                    {
                        "name": gettextCatalog.getString("Guernsey"),
                        "value": "GG"
                    },
                    {
                        "name": gettextCatalog.getString("Guinea"),
                        "value": "GN"
                    },
                    {
                        "name": gettextCatalog.getString("Guinea-Bissau"),
                        "value": "GW"
                    },
                    {
                        "name": gettextCatalog.getString("Guyana"),
                        "value": "GY"
                    },
                    {
                        "name": gettextCatalog.getString("Haiti"),
                        "value": "HT"
                    },
                    {
                        "name": gettextCatalog.getString("Heard Island and McDonald Islands"),
                        "value": "HM"
                    },
                    {
                        "name": gettextCatalog.getString("Holy See (Vatican City State)"),
                        "value": "VA"
                    },
                    {
                        "name": gettextCatalog.getString("Honduras"),
                        "value": "HN"
                    },
                    {
                        "name": gettextCatalog.getString("Hong Kong"),
                        "value": "HK"
                    },
                    {
                        "name": gettextCatalog.getString("Hungary"),
                        "value": "HU"
                    },
                    {
                        "name": gettextCatalog.getString("Iceland"),
                        "value": "IS"
                    },
                    {
                        "name": gettextCatalog.getString("India"),
                        "value": "IN"
                    },
                    {
                        "name": gettextCatalog.getString("Indonesia"),
                        "value": "ID"
                    },
                    {
                        "name": gettextCatalog.getString("Iran, Islamic Republic of"),
                        "value": "IR"
                    },
                    {
                        "name": gettextCatalog.getString("Iraq"),
                        "value": "IQ"
                    },
                    {
                        "name": gettextCatalog.getString("Ireland"),
                        "value": "IE"
                    },
                    {
                        "name": gettextCatalog.getString("Isle of Man"),
                        "value": "IM"
                    },
                    {
                        "name": gettextCatalog.getString("Israel"),
                        "value": "IL"
                    },
                    {
                        "name": gettextCatalog.getString("Italy"),
                        "value": "IT"
                    },
                    {
                        "name": gettextCatalog.getString("Jamaica"),
                        "value": "JM"
                    },
                    {
                        "name": gettextCatalog.getString("Japan"),
                        "value": "JP"
                    },
                    {
                        "name": gettextCatalog.getString("Jersey"),
                        "value": "JE"
                    },
                    {
                        "name": gettextCatalog.getString("Jordan"),
                        "value": "JO"
                    },
                    {
                        "name": gettextCatalog.getString("Kazakhstan"),
                        "value": "KZ"
                    },
                    {
                        "name": gettextCatalog.getString("Kenya"),
                        "value": "KE"
                    },
                    {
                        "name": gettextCatalog.getString("Kiribati"),
                        "value": "KI"
                    },
                    {
                        "name": gettextCatalog.getString("Korea, Democratic People's Republic of"),
                        "value": "KP"
                    },
                    {
                        "name": gettextCatalog.getString("Korea, Republic of"),
                        "value": "KR"
                    },
                    {
                        "name": gettextCatalog.getString("Kuwait"),
                        "value": "KW"
                    },
                    {
                        "name": gettextCatalog.getString("Kyrgyzstan"),
                        "value": "KG"
                    },
                    {
                        "name": gettextCatalog.getString("Lao People's Democratic Republic"),
                        "value": "LA"
                    },
                    {
                        "name": gettextCatalog.getString("Latvia"),
                        "value": "LV"
                    },
                    {
                        "name": gettextCatalog.getString("Lebanon"),
                        "value": "LB"
                    },
                    {
                        "name": gettextCatalog.getString("Lesotho"),
                        "value": "LS"
                    },
                    {
                        "name": gettextCatalog.getString("Liberia"),
                        "value": "LR"
                    },
                    {
                        "name": gettextCatalog.getString("Libya"),
                        "value": "LY"
                    },
                    {
                        "name": gettextCatalog.getString("Liechtenstein"),
                        "value": "LI"
                    },
                    {
                        "name": gettextCatalog.getString("Lithuania"),
                        "value": "LT"
                    },
                    {
                        "name": gettextCatalog.getString("Luxembourg"),
                        "value": "LU"
                    },
                    {
                        "name": gettextCatalog.getString("Macao"),
                        "value": "MO"
                    },
                    {
                        "name": gettextCatalog.getString("Macedonia, the Former Yugoslav Republic of"),
                        "value": "MK"
                    },
                    {
                        "name": gettextCatalog.getString("Madagascar"),
                        "value": "MG"
                    },
                    {
                        "name": gettextCatalog.getString("Malawi"),
                        "value": "MW"
                    },
                    {
                        "name": gettextCatalog.getString("Malaysia"),
                        "value": "MY"
                    },
                    {
                        "name": gettextCatalog.getString("Maldives"),
                        "value": "MV"
                    },
                    {
                        "name": gettextCatalog.getString("Mali"),
                        "value": "ML"
                    },
                    {
                        "name": gettextCatalog.getString("Malta"),
                        "value": "MT"
                    },
                    {
                        "name": gettextCatalog.getString("Marshall Islands"),
                        "value": "MH"
                    },
                    {
                        "name": gettextCatalog.getString("Martinique"),
                        "value": "MQ"
                    },
                    {
                        "name": gettextCatalog.getString("Mauritania"),
                        "value": "MR"
                    },

                    {
                        "name": gettextCatalog.getString("Mauritius"),
                        "value": "MU"
                    },
                    {
                        "name": gettextCatalog.getString("Mayotte"),
                        "value": "YT"
                    },
                    {
                        "name": gettextCatalog.getString("Mexico"),
                        "value": "MX"
                    },
                    {
                        "name": gettextCatalog.getString("Micronesia, Federated States of"),
                        "value": "FM"
                    },
                    {
                        "name": gettextCatalog.getString("Moldova, Republic of"),
                        "value": "MD"
                    },
                    {
                        "name": gettextCatalog.getString("Monaco"),
                        "value": "MC"
                    },
                    {
                        "name": gettextCatalog.getString("Mongolia"),
                        "value": "MN"
                    },
                    {
                        "name": gettextCatalog.getString("Montenegro"),
                        "value": "ME"
                    },
                    {
                        "name": gettextCatalog.getString("Montserrat"),
                        "value": "MS"
                    },
                    {
                        "name": gettextCatalog.getString("Morocco"),
                        "value": "MA"
                    },
                    {
                        "name": gettextCatalog.getString("Mozambique"),
                        "value": "MZ"
                    },
                    {
                        "name": gettextCatalog.getString("Myanmar"),
                        "value": "MM"
                    },
                    {
                        "name": gettextCatalog.getString("Namibia"),
                        "value": "NA"
                    },
                    {
                        "name": gettextCatalog.getString("Nauru"),
                        "value": "NR"
                    },
                    {
                        "name": gettextCatalog.getString("Nepal"),
                        "value": "NP"
                    },
                    {
                        "name": gettextCatalog.getString("Netherlands"),
                        "value": "NL"
                    },
                    {
                        "name": gettextCatalog.getString("New Caledonia"),
                        "value": "NC"
                    },
                    {
                        "name": gettextCatalog.getString("New Zealand"),
                        "value": "NZ"
                    },
                    {
                        "name": gettextCatalog.getString("Nicaragua"),
                        "value": "NI"
                    },
                    {
                        "name": gettextCatalog.getString("Niger"),
                        "value": "NE"
                    },
                    {
                        "name": gettextCatalog.getString("Nigeria"),
                        "value": "NG"
                    },
                    {
                        "name": gettextCatalog.getString("Niue"),
                        "value": "NU"
                    },
                    {
                        "name": gettextCatalog.getString("Norfolk Island"),
                        "value": "NF"
                    },
                    {
                        "name": gettextCatalog.getString("Northern Mariana Islands"),
                        "value": "MP"
                    },
                    {
                        "name": gettextCatalog.getString("Norway"),
                        "value": "NO"
                    },
                    {
                        "name": gettextCatalog.getString("Oman"),
                        "value": "OM"
                    },
                    {
                        "name": gettextCatalog.getString("Pakistan"),
                        "value": "PK"
                    },
                    {
                        "name": gettextCatalog.getString("Palau"),
                        "value": "PW"
                    },
                    {
                        "name": gettextCatalog.getString("Palestine, State of"),
                        "value": "PS"
                    },
                    {
                        "name": gettextCatalog.getString("Panama"),
                        "value": "PA"
                    },
                    {
                        "name": gettextCatalog.getString("Papua New Guinea"),
                        "value": "PG"
                    },
                    {
                        "name": gettextCatalog.getString("Paraguay"),
                        "value": "PY"
                    },
                    {
                        "name": gettextCatalog.getString("Peru"),
                        "value": "PE"
                    },
                    {
                        "name": gettextCatalog.getString("Philippines"),
                        "value": "PH"
                    },
                    {
                        "name": gettextCatalog.getString("Pitcairn"),
                        "value": "PN"
                    },
                    {
                        "name": gettextCatalog.getString("Poland"),
                        "value": "PL"
                    },
                    {
                        "name": gettextCatalog.getString("Portugal"),
                        "value": "PT"
                    },
                    {
                        "name": gettextCatalog.getString("Puerto Rico"),
                        "value": "PR"
                    },
                    {
                        "name": gettextCatalog.getString("Qatar"),
                        "value": "QA"
                    },
                    {
                        "name": gettextCatalog.getString("Reunion"),
                        "value": "RE"
                    },
                    {
                        "name": gettextCatalog.getString("Romania"),
                        "value": "RO"
                    },
                    {
                        "name": gettextCatalog.getString("Russian Federation"),
                        "value": "RU"
                    },
                    {
                        "name": gettextCatalog.getString("Rwanda"),
                        "value": "RW"
                    },
                    {
                        "name": gettextCatalog.getString("Saint Barthelemy"),
                        "value": "BL"
                    },
                    {
                        "name": gettextCatalog.getString("Saint Helena, Ascension and Tristan da Cunha"),
                        "value": "SH"
                    },
                    {
                        "name": gettextCatalog.getString("Saint Kitts and Nevis"),
                        "value": "KN"
                    },
                    {
                        "name": gettextCatalog.getString("Saint Lucia"),
                        "value": "LC"
                    },
                    {
                        "name": gettextCatalog.getString("Saint Martin (French part)"),
                        "value": "MF"
                    },
                    {
                        "name": gettextCatalog.getString("Saint Pierre and Miquelon"),
                        "value": "PM"
                    },
                    {
                        "name": gettextCatalog.getString("Saint Vincent and the Grenadines"),
                        "value": "VC"
                    },
                    {
                        "name": gettextCatalog.getString("Samoa"),
                        "value": "WS"
                    },
                    {
                        "name": gettextCatalog.getString("San Marino"),
                        "value": "SM"
                    },
                    {
                        "name": gettextCatalog.getString("Sao Tome and Principe"),
                        "value": "ST"
                    },
                    {
                        "name": gettextCatalog.getString("Saudi Arabia"),
                        "value": "SA"
                    },
                    {
                        "name": gettextCatalog.getString("Senegal"),
                        "value": "SN"
                    },
                    {
                        "name": gettextCatalog.getString("Serbia"),
                        "value": "RS"
                    },
                    {
                        "name": gettextCatalog.getString("Seychelles"),
                        "value": "SC"
                    },
                    {
                        "name": gettextCatalog.getString("Sierra Leone"),
                        "value": "SL"
                    },
                    {
                        "name": gettextCatalog.getString("Singapore"),
                        "value": "SG"
                    },
                    {
                        "name": gettextCatalog.getString("Sint Maarten (Dutch part)"),
                        "value": "SX"
                    },
                    {
                        "name": gettextCatalog.getString("Slovakia"),
                        "value": "SK"
                    },
                    {
                        "name": gettextCatalog.getString("Slovenia"),
                        "value": "SI"
                    },
                    {
                        "name": gettextCatalog.getString("Solomon Islands"),
                        "value": "SB"
                    },
                    {
                        "name": gettextCatalog.getString("Somalia"),
                        "value": "SO"
                    },
                    {
                        "name": gettextCatalog.getString("South Africa"),
                        "value": "ZA"
                    },
                    {
                        "name": gettextCatalog.getString("South Georgia and the South Sandwich Islands"),
                        "value": "GS"
                    },
                    {
                        "name": gettextCatalog.getString("South Sudan"),
                        "value": "SS"
                    },
                    {
                        "name": gettextCatalog.getString("Sri Lanka"),
                        "value": "LK"
                    },
                    {
                        "name": gettextCatalog.getString("Sudan"),
                        "value": "SD"
                    },
                    {
                        "name": gettextCatalog.getString("Suriname"),
                        "value": "SR"
                    },
                    {
                        "name": gettextCatalog.getString("Svalbard and Jan Mayen"),
                        "value": "SJ"
                    },
                    {
                        "name": gettextCatalog.getString("Eswatini"),
                        "value": "SZ"
                    },
                    {
                        "name": gettextCatalog.getString("Sweden"),
                        "value": "SE"
                    },
                    {
                        "name": gettextCatalog.getString("Switzerland"),
                        "value": "CH"
                    },
                    {
                        "name": gettextCatalog.getString("Syrian Arab Republic"),
                        "value": "SY"
                    },
                    {
                        "name": gettextCatalog.getString("Taiwan, Province of China"),
                        "value": "TW"
                    },
                    {
                        "name": gettextCatalog.getString("Tajikistan"),
                        "value": "TJ"
                    },
                    {
                        "name": gettextCatalog.getString("Tanzania, United Republic of"),
                        "value": "TZ"
                    },
                    {
                        "name": gettextCatalog.getString("Thailand"),
                        "value": "TH"
                    },
                    {
                        "name": gettextCatalog.getString("Timor-Leste"),
                        "value": "TL"
                    },
                    {
                        "name": gettextCatalog.getString("Togo"),
                        "value": "TG"
                    },
                    {
                        "name": gettextCatalog.getString("Tokelau"),
                        "value": "TK"
                    },
                    {
                        "name": gettextCatalog.getString("Tonga"),
                        "value": "TO"
                    },
                    {
                        "name": gettextCatalog.getString("Trinidad and Tobago"),
                        "value": "TT"
                    },
                    {
                        "name": gettextCatalog.getString("Tunisia"),
                        "value": "TN"
                    },
                    {
                        "name": gettextCatalog.getString("Turkey"),
                        "value": "TR"
                    },
                    {
                        "name": gettextCatalog.getString("Turkmenistan"),
                        "value": "TM"
                    },
                    {
                        "name": gettextCatalog.getString("Turks and Caicos Islands"),
                        "value": "TC"
                    },
                    {
                        "name": gettextCatalog.getString("Tuvalu"),
                        "value": "TV"
                    },
                    {
                        "name": gettextCatalog.getString("Uganda"),
                        "value": "UG"
                    },
                    {
                        "name": gettextCatalog.getString("Ukraine"),
                        "value": "UA"
                    },
                    {
                        "name": gettextCatalog.getString("United Arab Emirates"),
                        "value": "AE"
                    },
                    {
                        "name": gettextCatalog.getString("United Kingdom"),
                        "value": "GB"
                    },
                    {
                        "name": gettextCatalog.getString("United States"),
                        "value": "US"
                    },
                    {
                        "name": gettextCatalog.getString("United States Minor Outlying Islands"),
                        "value": "UM"
                    },
                    {
                        "name": gettextCatalog.getString("Uruguay"),
                        "value": "UY"
                    },
                    {
                        "name": gettextCatalog.getString("Uzbekistan"),
                        "value": "UZ"
                    },
                    {
                        "name": gettextCatalog.getString("Vanuatu"),
                        "value": "VU"
                    },
                    {
                        "name": gettextCatalog.getString("Venezuela, Bolivarian Republic of"),
                        "value": "VE"
                    },
                    {
                        "name": gettextCatalog.getString("Vietnam"),
                        "value": "VN"
                    },
                    {
                        "name": gettextCatalog.getString("Virgin Islands, British"),
                        "value": "VG"
                    },
                    {
                        "name": gettextCatalog.getString("Virgin Islands, U.S."),
                        "value": "VI"
                    },
                    {
                        "name": gettextCatalog.getString("Wallis and Futuna"),
                        "value": "WF"
                    },
                    {
                        "name": gettextCatalog.getString("Western Sahara"),
                        "value": "EH"
                    },
                    {
                        "name": gettextCatalog.getString("Yemen"),
                        "value": "YE"
                    },
                    {
                        "name": gettextCatalog.getString("Zambia"),
                        "value": "ZM"
                    },
                    {
                        "name": gettextCatalog.getString("Zimbabwe"),
                        "value": "ZW"
                    }
                ];
                //scope.selectedValue = scope.countries[1];

                Bookings.getCountriesFromInfo()
                    .then((priorityISO) => {
                        if (priorityISO == null || priorityISO.length === 0) {
                            scope.countries = countries;
                            return;
                        }
                        let priorityMap = new Map(priorityISO.map((iso, index) => [iso, index]));
                        let addedISOs = new Set();
                        let priorityCountries = [];
                        let otherCountries = [];
                        countries.forEach(country => {
                            if (!addedISOs.has(country.value)) {
                                if (priorityMap.has(country.value)) {
                                    priorityCountries.push(country);
                                } else {
                                    otherCountries.push(country);
                                }
                                addedISOs.add(country.value);
                            }
                        });
                        priorityCountries.sort((a, b) => priorityMap.get(a.value) - priorityMap.get(b.value));
                        scope.countries = priorityCountries.concat(otherCountries);
                    });
            }
        };
    }]);
