/*jslint node: true */
/*global angular */
/*global document */

"use strict";

angular
    .module('auto_height', [])
    .directive('autoHeight', ['$timeout', '$window', function ($timeout, $window) {
        return {
            link: function (scope, element, attrs) {

                var combineHeights = function (collection) {
                    var heights, i, len, node;
                    heights = 0;
                    for (i = 0, len = collection.length; i < len; i++) {
                        node = collection[i];
                        heights += node.offsetHeight;
                    }
                    return heights;
                };

                var siblings = function ($elm) {
                    var elm, i, len, ref, results;
                    ref = $elm.parent().children();
                    results = [];
                    for (i = 0, len = ref.length; i < len; i++) {
                        elm = ref[i];
                        if (elm !== $elm[0]) {
                            results.push(elm);
                        }
                    }
                    return results;
                };

                angular.element($window).bind('resize', function () {
                    var additionalHeight, parentHeight;
                    additionalHeight = attrs.additionalHeight || 0;
                    parentHeight = $window.innerHeight - element.parent()[0].getBoundingClientRect().top;
                    var newHeight = parentHeight - combineHeights(siblings(element)) - additionalHeight;
                    if (newHeight <= 200) newHeight = 200;
                    return element.css('height', newHeight + "px");
                });

                $timeout(function () {
                    return angular.element($window).triggerHandler('resize');
                }, 100);
            }
        };
    }]);
