/*jslint node: true */
"use strict";
/*globals angular */

angular
    .module('loading_service', [])
    .factory('loadingService', ['$timeout', function ($timeout) {
        var loadings = {};
        var flagsTimeOut = {};
        return {
            isActive: function (zone) {
                if (loadings[zone]) return true;
                else return false;
            },
            enable: function (zone, msg, floatFlag, timeout) {
                if (!timeout && timeout !== 0) {
                    timeout = 1000;
                }
                if (flagsTimeOut[zone]) {
                    $timeout.cancel(flagsTimeOut[zone]);
                }
                flagsTimeOut[zone] = $timeout(function () {
                    loadings[zone] = {};
                    loadings[zone].msg = msg;
                    if (floatFlag) loadings[zone].floatFlag = true;
                }, timeout);
            },
            disable: function (zone) {
                //$timeout(function() {
                $timeout.cancel(flagsTimeOut[zone]);
                delete flagsTimeOut[zone];
                delete loadings[zone];
                //}, 1000);
            },
            getMessage: function (zone) {
                return loadings[zone].msg;
            },
            isFloating: function (zone) {
                if (loadings[zone].floatFlag) return true;
                else return false;
            }
        };
    }])
    .directive('loadingDir', ['loadingService', 'myConfig', function (loadingService, myConfig) {
        return {
            restrict: 'E',
            // templateUrl: "templates/loading.html",
            templateUrl: function () {
                const template = 'templates/loading.html';
                return myConfig.production ?
                    `${myConfig.middlewareHost}/${myConfig.rootName}/${template}?version=${myConfig.version}` :
                    template;
            },
            scope: {
                zone: '='
            },
            link: function (scope, element, attrs) {
                scope.image = "static/img/spin.svg";
                scope.$watch(function () {
                    return loadingService.isActive(attrs.zone);
                }, function (flag) {
                    if (flag) {
                        element.show();
                        // element.css('display', 'block');
                        if (loadingService.isFloating(attrs.zone)) {
                            element.addClass('loading-container');
                            element.find('#ajax_loader').addClass('loading-content');
                        }
                        scope.message = loadingService.getMessage(attrs.zone);
                    } else {
                        element.removeClass('loading-container');
                        element.find('#ajax_loader').removeClass('loading-content');
                        element.hide();
                        // element.css('display', 'none');
                    }
                });
            }
        };
    }]);
