angular
    .module('login',['api'])
    .config(LoginConfig)
	.controller('LoginCtrl', LoginCtrl);

LoginConfig.$inject = ['$stateProvider'];
LoginCtrl.$inject = ['$state', 'Api', '$timeout', 'alertsService'];

function LoginConfig($stateProvider){
	$stateProvider.state( 'login', {
		url: '/login',
		views: {
			'content': {
				templateUrl: 'templates/login.html',
				controller: 'LoginCtrl',
                controllerAs: 'vm'
			}
		}
	});
}

function LoginCtrl($state, Api, $timeout, alertsService) {
	const vm = this;

	vm.classLogin = '';

	vm.login = function(){
		if(vm.idBooking && vm.email){
			Api.clearCache();
			Api.getSections(null, vm.idBooking, vm.email).then(function(res){
				if(res.expressChecking === "Si"){
					$state.go("checkin", {cryptedIdBooking: res.cryptedIdBooking});
				} else {
					$state.go("booking", {cryptedIdBooking: res.cryptedIdBooking});
				}
			}, function(reason) {
				// vm.idBooking = '';
				// vm.email = '';
				shakeBox();
				alertsService.new("login", "warning", reason);
				// alertsService.newError("Unexpected error in service", reason);
			});
		} else {
            shakeBox();
		}
	};

	function shakeBox() {
        vm.classLogin = 'shake';
        $timeout(function(){
            vm.classLogin = '';
        }, 1000);
	}

}
