/*jslint node: true */
"use strict";
/*globals angular, Image */

angular
    .module('bg_img', [])
    .directive('bgImg', ['myConfig', '$timeout', function (myConfig, $timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {

                scope.$watch(function () {
                    return attrs.url;
                }, function (newVal) {
                    if (newVal) {
                        var splitUrl = attrs.url.split("/");
                        var tempImg = new Image();

                        element[0].style['background-image'] = "url('" + attrs.url + "')";
                        element[0].style['background-size'] = "cover";

                        // element[0].style['background-repeat'] = "no-repeat";
                        // element[0].style['background-position'] = "center";
                        // element[0].style['background-image'] = "url('static/img/spin.svg')";

                        //var timeoutFlag = $timeout(function(){
                        //console.log("ON timeoutFlag " + attrs.url);
                        // TODO Repair retarted loading
                        // element[0].style['background-image'] = "url('static/img/spin.svg')";
                        //}, 1000);

                        const imageTemplate = 'static/img/spin.svg';
                        const noImagePath = myConfig.production ?
                            `${myConfig.middlewareHost}/${myConfig.rootName}/${imageTemplate}?version=${myConfig.version}` :
                            imageTemplate;

                        if (element[0].offsetWidth < 80 && element[0].offsetHeight < 80) {
                            element[0].style['background-size'] = "contain";
                        }
                        if (splitUrl[splitUrl.length - 1] !== 'undefined' && splitUrl[splitUrl.length - 1] !== '') {
                            if (attrs.inherit) element[0].style['background-size'] = "inherit";
                            tempImg.src = attrs.url;
                        } else {
                            //$timeout.cancel(timeoutFlag);
                            if (attrs.display === 'none') element.hide();
                            else {
                                element[0].style['background-image'] = `url('${noImagePath}')`;
                                element[0].style['background-size'] = "contain";
                                element[0].style['background-repeat'] = "no-repeat";
                                element[0].style['background-position'] = "center";
                            }
                            return;
                        }

                        tempImg.onerror = function () {
                            //$timeout.cancel(timeoutFlag);
                            if (attrs.display === 'none') {
                                element.hide();
                                // element.css('display', 'none');
                            }
                            else {
                                element[0].style['background-image'] = `url('${noImagePath}')`;
                                element[0].style['background-size'] = "contain";
                                element[0].style['background-repeat'] = "no-repeat";
                                element[0].style['background-position'] = "center";
                            }
                        };
                        tempImg.onload = function () {
                            //$timeout.cancel(timeoutFlag);
                            $timeout(function(){
                                element[0].style['background-image'] = "url('" + attrs.url + "')";
                                element[0].style['background-size'] = "cover";
                            });

                        };
                    }
                });
            }
        };
    }]);
